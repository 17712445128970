import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './assets/scss/style.scss'

import { Grid } from '@material-ui/core'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container } from 'react-bootstrap/'

import Dashboard from './components/dashboard'
import WordMarks from './components/dashboard/WordMark'
import LogoMarks from './components/dashboard/LogoMark'
import PublishedMark from './components/dashboard/PublishedMark'
import Trademarks from './components/dashboard/Trademarks'

import Login from './components/Auth/Login'
import SignUp from './components/Auth/SignUp'
import { AppContext } from './context'
import TopNavbar from './components/includes/TopNavbar'
import FilesComponent from './components/Files'
import Home from './components/Home'
import Terms from './components/Pages/Terms'
import Privacy from './components/Pages/Privacy'
import NewApplications from './components/dashboard/NewApplications'
import Settings from './components/Settings'

const Routes = () => (
  <Switch>
    <Route exact path='/' component={Home} />
    <Route exact path='/login' component={Login} />
    <Route exact path='/signup' component={SignUp} />
    <Route path='/dashboard' exact component={Dashboard} />
    <Route path='/comparisons' exact component={WordMarks} />
    <Route path='/journal' exact component={LogoMarks} />
    <Route path='/published' exact component={PublishedMark} />
    <Route path='/new-applications' exact component={NewApplications} />
    <Route path='/trademark' exact component={Trademarks} />
    <Route path='/file/:name' exact component={FilesComponent} />
    <Route path='/terms-conditions' exact component={Terms} />
    <Route path='/privacy-policy' exact component={Privacy} />
    <Route path='/settings' exact component={Settings} />

    {/* <CheckAuth>
    
    </CheckAuth> */}
  </Switch>
)

const App = () => {
  const context = useContext(AppContext)

  const { isAuthVisible } = context

  return (
    <div>
      {!isAuthVisible ? (
        <Router>
          <TopNavbar />

          <main className='main_wrapper'>
            <Container fluid>
              <Grid container spacing={3}>
                {/* Comparisons */}
                <Grid item xs={12}>
                  <Routes />
                </Grid>
              </Grid>
            </Container>
          </main>
        </Router>
      ) : (
        <Router>
          <Routes />
        </Router>
      )}
    </div>
  )
}

export default App
