import axios from 'axios'

export const userLogin = async (payLoad) => {
  try {
    const { data } = await axios.post(`users/login`, payLoad)
    return data
  } catch (err) {
    return err
  }
}
export const userLogout = async (payload) => {
  return new Promise(async (resolve, reject) => {
    let url = `/users/logout`
    const options = {
      method: 'DELETE',
      data: payload,
      url: url,
    }
    try {
      const { status } = await axios(options)
      if (status === 200) {
        resolve()
      }
    } catch (err) {
      reject({ error: 'Error Occured' })
    }
  })
}
export const getUserProfile = async (id) => {
  try {
    const { data } = await axios.get(`users/${id}`)
    return data
  } catch (error) {
    return error
  }
}
export const changePassword = async (id, payLoad) => {
  try {
    const { data } = await axios.post(`users/${id}/changepassword`, payLoad)
    return data
  } catch (err) {
    return err
  }
}
export const forgotPassword = async (payLoad) => {
  try {
    const { data } = await axios.post(`users/forgotpassword`, payLoad)
    return data
  } catch (err) {
    return err
  }
}
export const userSignup = async (payLoad) => {
  try {
    const { data } = await axios.post(`users/signup`, payLoad)
    return data
  } catch (err) {
    return err
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userLogin,
  userLogout,
  getUserProfile,
  changePassword,
  forgotPassword,
  userSignup,
}
