import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap/";
import SettingsIcon from "@material-ui/icons/Settings";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CompareIcon from "@material-ui/icons/Compare";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import { NavLink } from "react-router-dom";
import LocalStorageService from "../../LocalStorageService";
import { userLogout, getUserProfile } from "../Auth/AuthService";
import ProfileModal from "../Profile";
import Logo from "../../assets/img/ipbloc.png";

const localStorageService = LocalStorageService.getService();
const TopNavbar = () => {
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState({});

  // let history = useHistory()
  const userLogoutHandler = async () => {
    const userObj = {
      userId: localStorageService.getUserId(),
    };
    return new Promise(async (resolve, reject) => {
      try {
        await userLogout(userObj);
        const rememberData = localStorageService.getRememberData();

        localStorageService.clearToken();
        localStorageService.setRememberData(rememberData);

        window.location.href = "/";

        resolve();
      } catch (err) {
        reject();
      }
    });
  };

  const getUserHandler = async () => {
    const res = await getUserProfile(localStorageService.getUserId());

    if (res) {
      setUserData(res);
    }
  };

  useEffect(() => {
    if (localStorageService.getToken()) {
      getUserHandler();
    }
  }, []);

  // const handleOpen = () => {
  //   setShowModal(true)
  // }

  const handleClose = () => setShowModal(false);
  return (
    <>
      <Navbar bg="white" fixed="top">
        <Container fluid>
          <Navbar.Brand>
            <NavLink to="/">
              <img alt=" IPBLOC " src={Logo} />
              <span>
                <strong>TM</strong>SPY
              </span>
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              <NavLink
                to="/dashboard"
                activeClassName="active"
                className="nav-link"
              >
                <p>
                  <DashboardIcon />
                </p>
                Dashboard
              </NavLink>
              <NavLink
                to="/comparisons"
                activeClassName="active"
                className="nav-link"
              >
                <p>
                  <CompareIcon />
                </p>
                Comparisons
              </NavLink>
              <NavLink
                to="/new-applications"
                activeClassName="active"
                className="nav-link"
              >
                <p>
                  <NoteAddIcon />
                </p>
                New Applications
              </NavLink>
              <NavLink
                to="/journal"
                activeClassName="active"
                className="nav-link"
              >
                <p>
                  <FindInPageIcon />
                </p>
                Journal
              </NavLink>

              <NavLink
                to="/published"
                activeClassName="active"
                className="nav-link"
              >
                <p style={{ height: 26 }}>
                  <i className="fas fa-trademark" aria-hidden="true"></i>
                  <i className="fas fa-upload ml-2" aria-hidden="true"></i>
                </p>
                My Published Marks
              </NavLink>
              <NavDropdown
                title={
                  <span>
                    <p>
                      <AssignmentIcon />
                    </p>
                    Notice
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <a className="dropdown-item" href="/file/corrigenda">
                  <AssignmentIcon className="mr-2" /> Corrigenda
                </a>
                <a className="dropdown-item" href="/file/notification">
                  <AssignmentIcon className="mr-2" /> Notification
                </a>
                <a className="dropdown-item" href="/file/registered">
                  <AssignmentIcon className="mr-2" /> Registered
                </a>
                <a className="dropdown-item" href="/file/renewal">
                  <AssignmentIcon className="mr-2" /> Renewals
                </a>
                <a className="dropdown-item" href="/file/prsection">
                  <AssignmentIcon className="mr-2" /> PR Section
                </a>
              </NavDropdown>

              <NavDropdown
                title={
                  <span>
                    <p>
                      <AccountCircleIcon />
                    </p>
                    {localStorageService.getUserName()}
                  </span>
                }
                id="basic-nav-dropdown"
              >
                {/* <NavDropdown.Item onClick={handleOpen}>
                  Profile
                </NavDropdown.Item> */}

                <NavLink className="dropdown-item" to="/trademark">
                  <BookmarksIcon className="mr-2" /> My Trademarks
                </NavLink>
                <a className="dropdown-item" href="/settings">
                  <SettingsIcon className="mr-2" /> Settings
                </a>
                <NavDropdown.Item onClick={userLogoutHandler}>
                  <ExitToAppIcon className="mr-2" />
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ProfileModal
        show={showModal}
        handleClose={handleClose}
        userData={userData}
      />
    </>
  );
};

export default TopNavbar;
