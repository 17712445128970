import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";

import { getAllJournals } from "../dashboard/WordMark/WordMarkService";
import LocalStorageService from "../../LocalStorageService";

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { getAllPdfFiles } from "./FileService";
import Loader from "../includes/Loader";

/**
 * @author
 * @function FilesComponent
 **/

const FilesComponent = (props) => {
  const { name } = useParams();
  const [journalData, setJournalData] = useState([]);
  const [journal, setJournal] = useState(null);
  const baseUrl = "https://tm-journal.s3.ap-south-1.amazonaws.com/";
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getPdfUrlHandler = useCallback(
    async (journal) => {
      setIsLoading(true);
      const res = await getAllPdfFiles(journal, name);

      if (res) {
        setError(false);
        setIsLoading(false);
      } else {
        setError(true);
        setIsLoading(false);
      }
    },
    [name]
  );

  const getJournals = useCallback(async () => {
    const res = await getAllJournals(LocalStorageService.getCompanyId());

    if (res.length > 0) {
      setJournalData(res);
      setJournal(res[0]);
      getPdfUrlHandler(res[0]);
    } else {
      setJournalData([]);
    }
  }, [getPdfUrlHandler]);
  const handleChange = (event) => {
    const { value } = event.target;

    setJournal(value);
    getPdfUrlHandler(value);
  };

  useEffect(() => {
    getJournals();
    document.body.classList.add("isNoticePage");
    return () => document.body.classList.remove("isNoticePage");
  }, [getJournals]);

  const handleLoad = (e) => {
    const iframe = e.target;
    //  console.log(e.target.contentDocument, 'testE')
    if (iframe) {
    }
  };

  const loadingComponent = (
    <div
      style={{
        position: "fixed",
        zIndex: 110,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(255,255,255,0.8)",
      }}
    >
      <Loader />
    </div>
  );

  return (
    <>
      <div className="top_head_table">
        <div className="select_wrap" style={{ maxWidth: 208 }}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">
              Select Journal
            </InputLabel>
            <Select
              key={journal}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={journal}
              onChange={handleChange}
              label="Journal"
            >
              {journalData.map((j, i) => (
                <MenuItem value={j} key={i}>
                  {j}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      {isLoading && loadingComponent}

      {error ? (
        <div className="data_not_found">
          <h2>Oops!</h2>
          <p>No Record Found</p>
        </div>
      ) : (
        <iframe
          id="myiframe"
          className="pdf_view_iframe"
          width="100%"
          src={`${baseUrl}${journal}/pages/${name}.pdf`}
          title="title"
          onLoad={handleLoad}
        ></iframe>
      )}
    </>
  );
};

export default FilesComponent;
