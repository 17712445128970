import React from 'react'
import PropTypes from 'prop-types'

export const RowSelectContext = React.createContext()

export class RowSelectProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rowSelectedDataTemp: [],
      journalContext: '',
    }
  }

  setRowSelectedDataContext = (data) => {
    this.setState({
      rowSelectedDataTemp: data,
    })
  }

  render() {
    const { children } = this.props

    return (
      <RowSelectContext.Provider
        value={{
          ...this.state,

          setRowSelectedDataContext: this.setRowSelectedDataContext,
        }}>
        {children}
      </RowSelectContext.Provider>
    )
  }
}

RowSelectProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
}
