import React from 'react'
import PropTypes from 'prop-types'

export const AppContext = React.createContext()

export class AppProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      test: 'Hello Testing',
      isAuthVisible: false,
      pageTitle: '',
      selectedValue: 'applicationNo',
      rowSelectedDataTemp: [],
      markUpdate: {},
      logoMarkDataContext: [],
      comparisionPdfData: [],
      newApplicationPdfData: [],
      journalPdfData: [],
      myPublishPdfData: [],
      myTrademarkPdfData: [],
      journalContext: '',
    }
  }

  setAuthVisible = (flag) => {
    this.setState({
      isAuthVisible: flag,
    })
  }
  setPageTitle = (title) => {
    this.setState({
      pageTitle: title,
    })
  }

  setSelectedSearchValue = (value) => {
    this.setState({
      selectedValue: value,
    })
  }

  setRowSelectedDataContext = (data) => {
    this.setState({
      rowSelectedDataTemp: data,
    })
  }
  setMarkUpdateContext = (markObj) => {
    this.setState({
      markUpdate: markObj,
    })
  }
  setLogoMarkDataContext = (data) => {
    this.setState({
      logoMarkDataContext: data,
    })
  }

  setComparisionPdfData = (type, data) => {
    this.setState({ [type]: data })
  }
  setJournalContext = (data) => {
    this.setState({ journalContext: data })
  }

  render() {
    const { children } = this.props

    return (
      <AppContext.Provider
        value={{
          ...this.state,
          setAuthVisible: this.setAuthVisible,
          setPageTitle: this.setPageTitle,
          setSelectedSearchValue: this.setSelectedSearchValue,
          setRowSelectedDataContext: this.setRowSelectedDataContext,
          setMarkUpdateContext: this.setMarkUpdateContext,
          setLogoMarkDataContext: this.setLogoMarkDataContext,
          setComparisionPdfData: this.setComparisionPdfData,
          setJournalContext: this.setJournalContext,
        }}>
        {children}
      </AppContext.Provider>
    )
  }
}

AppProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
}
