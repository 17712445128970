import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Modal } from "react-bootstrap/";
import {
  Button,
  FormControl,
  FilledInput,
  InputLabel,
  InputAdornment,
  IconButton,
} from "@material-ui/core/";
import MuiAlert from "@material-ui/lab/Alert";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { changePassword } from "../Auth/AuthService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  withoutLabel: {
    marginTop: theme.spacing(3),
  },
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const ProfileModal = ({ show, handleClose, userData }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [open, setOpen] = React.useState(false);
  const [invalidField, setInvalidField] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setInvalidField(false);
    setErrorMessage("");
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const changePasswordHandler = async () => {
    const passwordObj = {
      password: values.password,
    };
    if (values.password) {
      await changePassword(userData.userId, passwordObj);
      setOpen(true);
      setValues({ password: "" });
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    } else {
      setInvalidField(true);
      setErrorMessage("This field is required");
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      className="profile_modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="row mb-3">
          <div class="col-md-8 details">
            <blockquote>
              <h5>{userData.name}</h5>
            </blockquote>
            <p>
              <strong> User:</strong> {userData.userId} <br />
              <strong>Company Name:</strong> {userData.company}
            </p>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <FormControl
                className={clsx(classes.margin, classes.textField)}
                variant="filled"
              >
                <InputLabel htmlFor="filled-adornment-password">
                  Password
                </InputLabel>
                <FilledInput
                  id="filled-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  required={true}
                  onChange={handleChange("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {invalidField && (
                  <label className="text-danger">{errorMessage}</label>
                )}
              </FormControl>
            </div>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              onClick={changePasswordHandler}
            >
              Update Password
            </Button>
          </div>
        </div>
        {open && (
          <Alert severity="success">
            Your password has been successfully changed
          </Alert>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ProfileModal;
