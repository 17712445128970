/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/img/ipbloc.png'
/**
 * @author
 * @function Footer
 **/

const Footer = (props) => {
  return (
    <>
      <footer>
        <div class='container'>
          <div class='row'>
            <div class='col-sm-6 col-md-4 col-lg-3'>
              <h4 class='footer-head'>About Product</h4>
              <ul class='footer-links'>
                <li>
                  <Link to='/terms-conditions' title='Terms and Conditions'>
                    Terms and Conditions
                  </Link>
                </li>
                <li>
                  <Link to='/privacy-policy' title='Privacy Policy'>
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div class='col-sm-6 col-md-4 col-lg-3'>
              <h4 class='footer-head'>Useful Links</h4>
              <ul class='footer-links'>
                <li>
                  <a
                    href='https://ipindiaonline.gov.in/tmrpublicsearch/frmmain.aspx'
                    title='Link'
                    target='_blank'
                    rel='noreferrer'>
                    TM Search
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.wipo.int/portal/en/index.html'
                    title='Link'
                    target='_blank'
                    rel='noreferrer'>
                    WIPO
                  </a>
                </li>
              </ul>
            </div>
            <div class='col-sm-6 col-md-4 col-lg-3'>
              <h4 class='footer-head'>Follow us</h4>
              <ul class='footer-links'>
                <li>
                  <a
                    href='https://www.linkedin.com/company/ipbloc/'
                    title='Link'>
                    LinkedIn
                  </a>
                </li>
                <li>
                  <a href='#' title='Link'>
                    Facebook
                  </a>
                </li>
              </ul>
            </div>
            <div class='col-sm-6 col-md-4 col-lg-3'>
              <h4 class='footer-head'>Contact Us</h4>
              <ul class='footer-links'>
                <li>
                  <a href='mailto:tmspy@ipbloc.in' title='Mail'>
                    tmspy@ipbloc.in
                  </a>
                </li>
              </ul>
              {/* <h4 className="footer-head">Follow Us</h4>
                <ul className="footer-share-it">
                  <li className="shate-it-item">
                    <a href="#" className="btn btn-share-sm bg-facebook">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li className="shate-it-item">
                    <a href="#" className="btn btn-share-sm bg-twitter">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li className="shate-it-item">
                    <a href="#" className="btn btn-share-sm bg-google">
                      <i className="fa fa-google-plus"></i>
                    </a>
                  </li>
                </ul> */}
            </div>
          </div>
          <div class='copyright-bar clearfix'>
            <div class='row'>
              <div class='col-lg-6'>
                <img style={{ height: 66 }} src={Logo} alt='TM Logo' />
                <span className='footer_logo'>
                  <strong>TM</strong>SPY
                </span>
              </div>
              <div class='col-lg-6'>
                <div class='copy-text'>© 2020 ip-bloc. All Rights Reserved.</div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
