import axios from 'axios'

export const getAllPdfFiles = async (journal, name) => {
  try {
    let isFile = false
    const res = await axios.get(`journals/${journal}/pages/${name}.pdf`)
    console.log(res, 'testRes')
    if (res.data) {
      isFile = true
    } else {
      isFile = false
    }
    return isFile
  } catch (err) {
    console.log(err, 'error')

    return false
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllPdfFiles,
}
