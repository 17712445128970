/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

// ES6
import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react'

import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Snackbar,
  TextField,
  InputAdornment,
  IconButton,
  Checkbox,
} from '@material-ui/core/'
import Table from 'react-bootstrap/Table'

import SearchIcon from '@material-ui/icons/Search'
import { useHistory } from 'react-router-dom'
import { AppContext } from '../../../context'
import { RowSelectContext } from '../../../context/RowSelectContext'
import {
  getPublishedMark,
  getAllJournals,
  getSearchedWordMarkData,
} from './PublishedMarkService'
import PdfControl from '../PDF/PdfControl'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import LocalStorageService from '../../../LocalStorageService'
import config from '../../../environment'
import Loader from '../../includes/Loader'
import Pagination from '@material-ui/lab/Pagination'
import MuiAlert from '@material-ui/lab/Alert'

const localStorageService = LocalStorageService.getService()

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}
let rowSelectedList = []
const PublishedMark = () => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const context = useContext(AppContext)
  const contextRowSelect = useContext(RowSelectContext)
  const { setComparisionPdfData } = context
  let history = useHistory()
  const [publishedMarks, setPublishedMarks] = useState([])
  const [journalData, setJournalData] = useState([])
  const [journal, setJournal] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [currentRowData, setCurrentRowData] = useState([])
  const [infrigementType, setInfrigementType] = useState('sameClass')
  const [rowSelectedData, setRowSelectedData] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [page, setPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [open, setOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedValue, setSelectedValue] = useState('applicationNo')
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const handleValueChange = (event) => {
    setSelectedValue(event.target.value)
  }

  const handleChange = (event) => {
    const { setRowSelectedDataContext } = contextRowSelect
    setIsLoading(true)
    const selectedValue = event.target.value
    setJournal(selectedValue)
    setPage(1)
    rowSelectedList = []
    setPublishedMarks([])
    getAllWordMarkList(selectedValue, infrigementType, page)
    setRowSelectedDataContext(rowSelectedList)
  }
  const handlePageChange = (event, value) => {
    setIsLoading(true)
    setPage(value)

    getAllWordMarkList(journal, infrigementType, value)
  }

  const getJournals = useCallback(async () => {
    const res = await getAllJournals(localStorageService.getCompanyId())

    if (res.length > 0) {
      setJournalData(res)
      setTimeout(() => {
        setJournal(res[0])
        getAllWordMarkList(res[0], infrigementType, page)
      }, 200)
    } else {
    }
  }, [infrigementType, page])

  useEffect(() => {
    const { setAuthVisible } = context
    const { setRowSelectedDataContext } = contextRowSelect
    if (localStorageService.getToken()) {
      setAuthVisible(false)
      setTimeout(() => {
        getJournals()
      }, 1000)
    } else {
      history.push('/login')
    }
    return () => {
      setPublishedMarks([])
      setRowSelectedDataContext([])
      rowSelectedList = []
      setComparisionPdfData('myPublishPdfData', [])
    }
  }, [getJournals])

  const getAllWordMarkList = async (journalObj) => {
    const res = await getPublishedMark(journalObj)

    const publishedMark = res.Items

    if (publishedMark && publishedMark.length > 0) {
      // const sorted = await publishedMark.sort((a, b) =>
      //   a.score > b.score ? -1 : 1
      // );

      setPublishedMarks(publishedMark)
      setComparisionPdfData('myPublishPdfData', publishedMark)

      setPageCount(res.Count)
      setIsLoading(false)
    } else {
      setPublishedMarks([])
      setIsLoading(false)
    }
  }

  const handleSearch = (e) => {
    const searchValue = e.target.value.trim()

    setSearchQuery(searchValue)
    if (searchValue && searchValue.length > 0) {
      // getSearchedData(searchValue);
    } else {
      setIsLoading(true)
      setCurrentRowData([])
      getAllWordMarkList(journal, infrigementType, page)
    }
  }

  const getSearchedData = async (valueObj) => {
    let isValid = false
    const isNum = /^\d+$/.test(valueObj)
    if (!isNum && selectedValue === 'applicationNo') {
      setOpen(true)
      setErrorMessage('Please enter number')
      isValid = false
      return
    } else if (isNum) {
      isValid = true
    } else if (!isNum) {
      isValid = true
    } else if (isNum && selectedValue === 'mark') {
      setOpen(true)
      setErrorMessage('Please enter mark')
    }

    if (isValid) {
      setIsLoading(true)
      const resData = await getSearchedWordMarkData(
        journal,
        selectedValue,
        valueObj
      )
      const publishedMark = resData.Items

      if (publishedMark && publishedMark.length > 0) {
        // const sorted = await wordMarkDataTemp.sort((a, b) =>
        //   a.score > b.score ? -1 : 1
        // );

        setPublishedMarks(publishedMark)
        setPageCount(resData.Count)
        setIsLoading(false)
      } else {
        setPublishedMarks([])
        setIsLoading(false)
      }
    }
  }

  const searchHandler = () => {
    getSearchedData(searchQuery)
  }

  const rowSelectHandler = (appNo, e) => {
    const { setRowSelectedDataContext } = contextRowSelect

    if (e.target.checked) {
      const selectedRow = publishedMarks.find(
        (p) => p.applicationNo === appNo.applicationNo
      )
      rowSelectedList.push({ ...selectedRow })

      setRowSelectedData(rowSelectedList)
      setRowSelectedDataContext(rowSelectedList)
    } else {
      const rowIndex = rowSelectedList.findIndex(
        (p) => p.applicationNo === appNo.applicationNo
      )
      rowSelectedList.splice(rowIndex, 1)
      setRowSelectedDataContext(rowSelectedList)
    }
  }

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  const loadingComponent = (
    <div
      style={{
        position: 'fixed',
        zIndex: 110,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255,255,255,0.8)',
      }}>
      <Loader />
    </div>
  )

  return (
    <>
      <div className='top_head_table'>
        <div className='select_wrap' style={{ maxWidth: 170 }}>
          <FormControl variant='filled' fullWidth>
            <InputLabel id='demo-simple-select-outlined-label'>
              Select Journal
            </InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              value={journal}
              onChange={handleChange}
              label='Journal'>
              {journalData.map((j, i) => (
                <MenuItem value={j} key={i}>
                  {j}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className='search_wrap'>
          <TextField
            id='filled-basic'
            label='Search'
            variant='filled'
            className='search_box'
            onChange={handleSearch}
            value={searchQuery}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={searchHandler}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControl variant='filled' className='ml-1 select_column'>
            <InputLabel id='demo-simple-select-label'>Select column</InputLabel>
            <Select
              labelId='demo-simple-select-filled-label'
              id='demo-simple-select-filled'
              value={selectedValue}
              onChange={handleValueChange}>
              <MenuItem value='applicationNo'>Application No</MenuItem>
              <MenuItem value='mark'>Mark</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className='pagination_wrap'>
          <Pagination
            count={pageCount}
            page={page}
            color='primary'
            onChange={handlePageChange}
          />
        </div>
      </div>
      {isLoading && loadingComponent}
      <div className='table_body_wrap'>
        <div className='title_head'>
          <h5>Published</h5>
          <PdfControl
            exportToCSV={exportToCSV}
            searchedData={currentRowData}
            rowSelectedData={rowSelectedData}
            fromPublishedMark
          />
        </div>

        <Table bordered size='sm'>
          <thead>
            <tr>
              <th></th>
              <th>Mark</th>
              <th>Class</th>
              <th>Page</th>
              <th>Application No</th>
            </tr>
          </thead>
          <tbody>
            {publishedMarks.length > 0 ? (
              <>
                {publishedMarks.map((word, i) => (
                  <tr key={i}>
                    <td style={{ width: '35px' }}>
                      <Checkbox
                        color='primary'
                        checked={rowSelectedList.some((p) =>
                          p.applicationNo === word.applicationNo ? 1 : 0
                        )}
                        onClick={(e) => rowSelectHandler(word, e)}
                        inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                      />
                    </td>
                    <td style={{ width: '435px' }}>
                      {' '}
                      <strong>{word.mark}</strong>{' '}
                      {word.url && (
                        <img className='logo_mark' alt='' src={word.url} />
                      )}
                    </td>
                    <td style={{ width: '60px' }}>{word.class}</td>

                    <td style={{ width: '435px' }}>
                      {' '}
                      <strong>{word.page}</strong>{' '}
                    </td>

                    <td style={{ width: '135px' }}>
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href={`${config.PDF_URL}/${journal}/pages/${word.applicationNo}.pdf`}>
                        {word.applicationNo}
                      </a>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan='8'>Sorry no record found!</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='error'>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

export default PublishedMark
