// LocalStorageService.js
const LocalStorageService = (() => {
  let service;
  function getService() {
    if (!service) {
      service = this;

      return service;
    }

    return service;
  }
  const setToken = (tokenObj) => localStorage.setItem("token", tokenObj);
  const getToken = () => localStorage.getItem("token");

  const setCompanyId = (idObj) => localStorage.setItem("companyId", idObj);
  const getCompanyId = () => localStorage.getItem("companyId");

  const setUserRole = (roleObj) => localStorage.setItem("userRole", roleObj);
  const getUserRole = () => localStorage.getItem("userRole");

  const setUserName = (nameObj) => localStorage.setItem("userName", nameObj);
  const getUserName = (nameObj) => localStorage.getItem("userName");

  const setUserId = (userIdObj) => localStorage.setItem("userId", userIdObj);
  const getUserId = () => localStorage.getItem("userId");

  const setRememberData = (rememberObj) =>
    localStorage.setItem("remember", rememberObj);
  const getRememberData = () => localStorage.getItem("remember");

  const clearToken = () => localStorage.clear();

  return {
    getService,
    setToken,
    getToken,
    setCompanyId,
    getCompanyId,
    setUserRole,
    getUserRole,
    setUserName,
    getUserName,
    setUserId,
    getUserId,
    setRememberData,
    getRememberData,
    clearToken,
  };
})();
export default LocalStorageService;
