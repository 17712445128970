import axios from 'axios'
import LocalStorageService from '../../../LocalStorageService'

const localStorageService = LocalStorageService.getService()

export const getAllLogoMarksFromApi = async (
  journal,
  cType,
  page,
  journalType
) => {
  try {
    const { data } = await axios.get(
      `journals/${journal}?type=${journalType}&class=${cType}&page=${page}`
    )
    return data
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}

export const updateMark = async (journal, payload) => {
  try {
    const { data } = await axios.put(
      `journals/${journal}/pages/${payload.applicationNo}`,
      {
        mark: payload.mark,
      }
    )
    return data
  } catch (err) {
    return err
  }
}

export const getSearchedLogoMarkData = async (
  journal,
  type,
  classType,
  key,
  value
) => {
  try {
    const { data } = await axios.get(
      `journals/${journal}?companyId=${localStorageService.getCompanyId()}&type=${type}&class=${classType}&searchKey=${key}&searchValue=${value}`
    )
    return Promise.resolve(data)
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { getAllLogoMarksFromApi, updateMark, getSearchedLogoMarkData }
