/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Card, Col, Dropdown, Modal, Row, Table } from 'react-bootstrap'
import { InputLabel, MenuItem, FormControl, Select } from '@material-ui/core/'

import {
  getAllJournalsDashboard,
  getAllComparisonsDashboard,
  getAllTrackNewApplications,
  getAllPublishNewApplications,
} from './DashboardService'
import Loader from '../includes/Loader'
import config from '../../environment'

import { updateComparisonFlag } from './WordMark/WordMarkService'
import { updateApplication } from './NewApplications/NewApplicationService'

const Dashboard = () => {
  const [journalData, setJournalData] = useState([])
  const [journal, setJournal] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [comparisonData, setComparisonData] = useState([])
  const [message, setMessage] = useState('')
  const [show, setShow] = useState(false)
  const [comparisonDetails, setComparisonDetails] = useState({})
  const [allTrackApplications, setAllTrackApplications] = useState([])
  const [trackPage, setTrackPage] = useState(1)
  const [trackPageCount, setTrackPageCount] = useState(0)
  const [trackLoading, setTracLoading] = useState(false)
  const [trackMessage, setTrackMessage] = useState('')
  const [allPublishApplications, setAllPublishApplications] = useState([])
  const [publishPage, setPublishPage] = useState(1)
  const [publishPageCount, setPublishPageCount] = useState(0)
  const [publishLoading, setPublishLoading] = useState(false)
  const [publishMessage, setPublishMessage] = useState('')
  const [showApplicationModal, setShowApplicationModal] = useState(false)
  const [applicationDetails, setApplicationDetails] = useState({})
  const [typeOfApplication, setTypeOfApplication] = useState('')

  const handleClose = () => setShow(false)
  const handleShow = (data) => {
    const tempData = {
      applicationNo: data.applicationNo,
      class: data.class,
      mark: data.mark,
      score: data.score,
      conflictMark: data.conflictMark,
      conflictClass: data.conflictClass,
      conflictApplicationNo: data.conflictApplicationNo,
    }
    setComparisonDetails(tempData)
    setShow(true)
  }
  const handleCloseApplicationModal = () => setShowApplicationModal(false)
  const handleShowApplicationModal = (type, data) => {
    setTypeOfApplication(type)
    const tempData = {
      applicationNo: data.applicationNo,
      mark: data.mark,
      class: data.class,
      goods: data.goods,
      applicantName: data.applicantName,
      applicationDate: data.applicationDate,
    }
    setApplicationDetails(tempData)
    setShowApplicationModal(true)
  }

  const handleChange = (event) => {
    setIsLoading(true)
    const selectedValue = event.target.value
    setJournal(selectedValue)
    setPage(1)
    getAllComparisonsDashboardHandler(selectedValue, page)

    // getAllWordMarkList(selectedValue, infrigementType, page)
  }

  useEffect(() => {
    getAllJournalsHandler()
    getAllTrackNewApplicationsHandler()
    getAllPublishNewApplicationsHandler()
  }, [])

  const getAllJournalsHandler = async () => {
    setIsLoading(true)
    const res = await getAllJournalsDashboard()

    if (res.length > 0) {
      setJournalData(res)

      setTimeout(() => {
        setJournal(res[0])
        getAllComparisonsDashboardHandler(res[0], page)
      }, 200)
    } else {
      setJournalData([])
    }
  }

  const getAllComparisonsDashboardHandler = async (journalTemp, pageTemp) => {
    setIsLoading(true)

    const res = await getAllComparisonsDashboard(journalTemp, pageTemp)

    if (res && res.Items && res.Items.length > 0) {
      setIsLoading(false)
      setComparisonData(res.Items)
      setPageCount(res.Count)
      setMessage('')
    } else {
      setIsLoading(false)
      setComparisonData([])
      setMessage('No Data Found')
    }
  }

  const getAllTrackNewApplicationsHandler = async () => {
    setTracLoading(true)
    const res = await getAllTrackNewApplications()

    if (res && res.Items?.length > 0) {
      setTracLoading(false)
      setAllTrackApplications(res.Items)
      setTrackPageCount(res.Count)
      setTrackMessage('')
    } else {
      setAllTrackApplications([])
      setTrackMessage('No Data Found')

      setTracLoading(false)
    }
  }

  const getAllPublishNewApplicationsHandler = async () => {
    setPublishLoading(true)
    const res = await getAllPublishNewApplications()

    console.log(res, 'resData')

    if (res && res.Items?.length > 0) {
      setPublishLoading(false)
      setAllPublishApplications(res.Items)
      setPublishPageCount(res.Count)
      setPublishMessage('')
    } else {
      setPublishMessage('No Data Found')
      setPublishLoading(false)
      setAllPublishApplications([])
    }
  }

  const updateComparisonFlagHandler = async (id, type) => {
    let tempData = [...comparisonData]
    tempData.forEach((element) => {
      if (element.id === id) {
        element.oppose = type
      }
    })
    setComparisonData(tempData)
    const res = await updateComparisonFlag(journal, id, type)
    getAllComparisonsDashboardHandler(journal, page)

    if (res) {
      handleClose()
    }
  }
  const updateTrackingFlagHandler = async (appType, id, type) => {
    const res = await updateApplication(id, type)

    if (res) {
      if (appType === 'Track') {
        getAllTrackNewApplicationsHandler()
      } else {
        getAllPublishNewApplicationsHandler()
      }
      handleCloseApplicationModal()
    }
  }

  console.log(allPublishApplications, 'allPublishApplications')
  return (
    <>
      <div className='dashboard_wrapper'>
        <Row>
          <Col md={4}>
            <Card border='primary'>
              <Card.Header>
                Comparisons
                <FormControl variant='filled' fullWidth>
                  <InputLabel id='demo-simple-select-outlined-label'>
                    Select Journal
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    value={journal}
                    onChange={handleChange}
                    label='Journal'>
                    {journalData.map((j, i) => (
                      <MenuItem value={j} key={i}>
                        {j}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Card.Header>
              <Card.Body>
                {isLoading && <Loader />}
                {message && (
                  <h5 className='text-center no_data_found'>{message}</h5>
                )}
                {comparisonData.length > 0 && (
                  <Table bordered hover size='sm'>
                    <thead>
                      <tr>
                        <th>Application No.</th>
                        <th>Mark</th>
                      </tr>
                    </thead>
                    <tbody>
                      {comparisonData.map((item, i) => (
                        <tr>
                          <td style={{ width: 150 }}>
                            <a
                              href='javascript:void(0)'
                              onClick={() => handleShow(item)}>
                              {item.conflictApplicationNo}
                            </a>
                          </td>
                          <td>{item.mark}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card border='primary'>
              <Card.Header>New Application - Tracking</Card.Header>
              <Card.Body>
                {trackLoading && <Loader />}
                {trackMessage && (
                  <h5 className='text-center no_data_found'>{trackMessage}</h5>
                )}
                {allTrackApplications.length > 0 && (
                  <Table bordered hover size='sm'>
                    <thead>
                      <tr>
                        <th>Application No.</th>
                        <th>Mark</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allTrackApplications.map((item, i) => (
                        <tr>
                          <td style={{ width: 140 }}>
                            <a
                              href='javascript:void(0)'
                              onClick={() =>
                                handleShowApplicationModal('Track', item)
                              }>
                              {item.applicationNo}
                            </a>
                          </td>
                          <td>{item.mark}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card border='primary'>
              <Card.Header>New Application - Published</Card.Header>
              <Card.Body>
                {publishLoading && <Loader />}
                {publishMessage && (
                  <h5 className='text-center no_data_found'>
                    {publishMessage}
                  </h5>
                )}
                {allPublishApplications.length > 0 && (
                  <Table bordered hover size='sm'>
                    <thead>
                      <tr>
                        <th>Application No.</th>
                        <th>Mark</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allPublishApplications.map((item, i) => (
                        <tr>
                          <td style={{ width: 140 }}>
                            <a
                              href='javascript:void(0)'
                              onClick={() =>
                                handleShowApplicationModal('Publish', item)
                              }>
                              {item.applicationNo}
                            </a>
                          </td>
                          <td>{item.mark}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal show={show} onHide={handleClose} centered className='detail_modal'>
        <Modal.Header closeButton>
          <Modal.Title>Opposition Tracking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered hover size='sm' className='comarision_table__t'>
            <tbody>
              {Object.keys(comparisonDetails).map((key, i) => {
                let trData
                let appNo = comparisonDetails['applicationNo']

                const tempComparison = comparisonData.find(
                  (p) => p.applicationNo === appNo
                )

                if (
                  key === 'applicationNo' ||
                  key === 'mark' ||
                  key === 'class' ||
                  key === 'score'
                ) {
                  trData = (
                    <>
                      {i === 0 && (
                        <>
                          <tr>
                            <td colspan='2'>
                              <h4 className='score_text'>
                                Score:{' '}
                                <strong>{comparisonDetails['score']}</strong>
                              </h4>
                            </td>
                          </tr>

                          <tr>
                            <td colspan='2'>
                              <h5 className='text-center m-0'>Client Mark</h5>
                            </td>
                          </tr>
                        </>
                      )}
                      <tr>
                        <td>
                          {key === 'applicationNo'
                            ? 'Application No'
                            : key === 'mark'
                            ? 'Mark'
                            : key === 'class'
                            ? 'Class'
                            : key === 'mark'
                            ? 'Mark'
                            : key === 'conflictMark'
                            ? 'Mark'
                            : key === 'conflictClass'
                            ? 'Class'
                            : key === 'conflictApplicationNo'
                            ? 'Application No.'
                            : ''}
                        </td>

                        <td className='d-flex align-items-center'>
                          {key === 'applicationNo' ? (
                            <>
                              {comparisonDetails[key]}
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant='success'
                                  id='dropdown-basic'>
                                  <i
                                    className={`fas fa-flag ${
                                      tempComparison?.oppose === 'RED'
                                        ? 'red'
                                        : tempComparison?.oppose === 'GREEN'
                                        ? 'green'
                                        : tempComparison?.oppose === 'NONE' &&
                                          'none'
                                    }`}></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    href='javascript:void(0)'
                                    title='Opposition Tracking'
                                    onClick={() =>
                                      updateComparisonFlagHandler(
                                        tempComparison.id,
                                        'RED'
                                      )
                                    }>
                                    <p>Opposition Tracking</p>
                                    <i class='fas fa-flag red'></i>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    href='javascript:void(0)'
                                    title='Opposition Filed'
                                    onClick={() =>
                                      updateComparisonFlagHandler(
                                        tempComparison.id,
                                        'GREEN'
                                      )
                                    }>
                                    <p>Opposition Filed</p>
                                    <i class='fas fa-flag green'></i>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    href='javascript:void(0)'
                                    onClick={() =>
                                      updateComparisonFlagHandler(
                                        tempComparison.id,
                                        'NONE'
                                      )
                                    }>
                                    <p>None</p>

                                    <i class='fas fa-flag '></i>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </>
                          ) : key === 'mark' ? (
                            <>
                              <p>
                                <strong>{comparisonDetails[key]}</strong>{' '}
                              </p>
                              {tempComparison?.cLogo && (
                                <img
                                  className='ml-4'
                                  src={tempComparison.cLogo}
                                  alt=''
                                />
                              )}
                            </>
                          ) : key === 'score' ? (
                            ''
                          ) : (
                            comparisonDetails[key]
                          )}
                        </td>
                      </tr>
                    </>
                  )
                }
                if (
                  key === 'conflictMark' ||
                  key === 'conflictClass' ||
                  key === 'conflictApplicationNo'
                ) {
                  trData = (
                    <>
                      {i === 4 && (
                        <tr>
                          <td colspan='2'>
                            <h5 className='text-center m-0'>Conflict Mark</h5>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>
                          {key === 'applicationNo'
                            ? 'Application No'
                            : key === 'mark'
                            ? 'Mark'
                            : key === 'class'
                            ? 'Class'
                            : key === 'mark'
                            ? 'Mark'
                            : key === 'score'
                            ? 'Score'
                            : key === 'conflictMark'
                            ? 'Mark'
                            : key === 'conflictClass'
                            ? 'Class'
                            : key === 'conflictApplicationNo'
                            ? 'Application No.'
                            : ''}
                        </td>

                        <td className='d-flex align-items-center'>
                          {key === 'conflictApplicationNo' ? (
                            <a
                              target='_blank'
                              rel='noopener noreferrer'
                              href={`${config.PDF_URL}/${journal}/pages/${comparisonDetails[key]}.pdf`}>
                              {comparisonDetails[key]}
                            </a>
                          ) : key === 'conflictMark' ? (
                            <>
                              <p>
                                {' '}
                                <strong>{comparisonDetails[key]}</strong>{' '}
                              </p>
                              {tempComparison?.url && (
                                <img
                                  className='ml-4'
                                  src={tempComparison.url}
                                  alt=''
                                />
                              )}
                            </>
                          ) : (
                            comparisonDetails[key]
                          )}
                        </td>
                      </tr>
                    </>
                  )
                }
                return trData
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
      <Modal
        show={showApplicationModal}
        onHide={handleCloseApplicationModal}
        centered
        className='detail_modal'>
        <Modal.Header closeButton>
          <Modal.Title>
            {typeOfApplication === 'Track'
              ? 'Track Application'
              : 'Publish Application'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered hover size='sm'>
            <tbody>
              {Object.keys(applicationDetails).map((key, i) => {
                let appNo = applicationDetails['applicationNo']

                const tempApplication =
                  typeOfApplication === 'Track'
                    ? allTrackApplications.find(
                        (p) => p.applicationNo === appNo
                      )
                    : allPublishApplications.find(
                        (p) => p.applicationNo === appNo
                      )

                return (
                  <tr>
                    <td>
                      {key === 'applicationNo'
                        ? 'Application No'
                        : key === 'class'
                        ? 'Class'
                        : key === 'mark'
                        ? 'Mark'
                        : key === 'goods'
                        ? 'Goods'
                        : key === 'applicantName'
                        ? 'Applicant Name'
                        : key === 'applicationDate'
                        ? 'Application Date'
                        : ''}
                    </td>

                    <td className='d-flex align-items-center'>
                      {key === 'applicationNo' ? (
                        <>
                          {applicationDetails[key]}
                          <Dropdown>
                            <Dropdown.Toggle
                              variant='success'
                              id='dropdown-basic'>
                              <i
                                class={`fas fa-flag ${
                                  tempApplication?.marker === 'TRACKING'
                                    ? 'red'
                                    : tempApplication?.marker === 'PUBLISHED'
                                    ? 'green'
                                    : tempApplication?.marker === 'NONE'
                                    ? 'none'
                                    : ''
                                }`}></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                href='javascript:void(0)'
                                onClick={() =>
                                  updateTrackingFlagHandler(
                                    typeOfApplication === 'Track'
                                      ? 'Track'
                                      : 'Publish',
                                    applicationDetails[key],
                                    'NONE'
                                  )
                                }>
                                <p>None</p>

                                <i class='fas fa-flag '></i>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </>
                      ) : key === 'mark' ? (
                        <>
                          <p>
                            {' '}
                            <strong>{applicationDetails[key]}</strong>{' '}
                          </p>
                          {tempApplication?.url && (
                            <img
                              className='ml-4'
                              src={tempApplication.url}
                              alt=''
                            />
                          )}
                        </>
                      ) : (
                        applicationDetails[key]
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default Dashboard
