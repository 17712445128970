import React, { useEffect, useState } from 'react'
import { Col, Container, Modal, Table } from 'react-bootstrap'
import isEmpty from 'is-empty'
import {
  Button,
  FormControl,
  FilledInput,
  InputLabel,
  InputAdornment,
  IconButton,
  TextField,
  Snackbar,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core/'
import RefreshIcon from '@material-ui/icons/Refresh'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Chip from '@material-ui/core/Chip'
import Autocomplete from '@material-ui/lab/Autocomplete'
import MuiAlert from '@material-ui/lab/Alert'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Row } from 'react-bootstrap'
import LocalStorageService from '../../LocalStorageService'
import { changePassword, getUserProfile } from '../Auth/AuthService'
import {
  getExclusionlist,
  postExclusionlist,
  deleteExclusionlist,
  getNewapplications,
  postNewapplications,
  deleteNewApplicant,
  updateNewapplications,
} from './SettingService'

/**
 * @author
 * @function Settings
 **/

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  withoutLabel: {
    marginTop: theme.spacing(3),
  },
}))
function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const classeOption = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
]

const localStorageService = LocalStorageService.getService()
const Settings = (props) => {
  const classes = useStyles()
  const [userData, setUserData] = useState({})
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  })
  const [open, setOpen] = React.useState(false)
  const [invalidField, setInvalidField] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [classesList, setClassesList] = useState([])
  const [seggestedList, setSuggestedList] = useState([])
  const [exclutionList, setExclutionList] = useState([])

  const [openMessage, setOpenMessage] = useState(false)
  const [messageSuccess, setMessageSuccess] = useState('')
  const [newExclusion, setNewExclusion] = useState('')
  const [newApplicantList, setNewApplicantList] = useState([])
  const [openConfirm, setOpenConfirm] = useState(false)
  const [show, setShow] = useState(false)
  const [newApplicantName, setNewApplicantName] = useState('')
  const [deleteApplicant, setDeleteApplicant] = useState('')
  const [editNewApplicantData, setEditNewApplicantData] = useState({})

  const [openMessageError, setOpenMessageError] = useState(false)
  const [errorMessageSuccess, setErrorMessageSuccess] = useState('')

  const closeErrorMessageHandler = () => setOpenMessageError(false)
  const openErrorMessageHandler = () => setOpenMessageError(true)
  const handleClose = () => setShow(false)
  const handleShow = (data) => {
    setEditNewApplicantData(data)
    setShow(true)
  }

  const closeMessageHandler = () => setOpenMessage(false)
  const openMessageHandler = () => setOpenMessage(true)
  const handleCloseConfirm = () => {
    setOpenConfirm(false)
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
    setInvalidField(false)
    setErrorMessage('')
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const changePasswordHandler = async () => {
    const passwordObj = {
      password: values.password,
    }
    if (values.password) {
      await changePassword(userData.userId, passwordObj)
      setOpen(true)
      setValues({ password: '' })
      setTimeout(() => {
        setOpen(false)
      }, 3000)
    } else {
      setInvalidField(true)
      setErrorMessage('This field is required')
    }
  }

  const getUserHandler = async () => {
    const res = await getUserProfile(localStorageService.getUserId())

    if (res) {
      setUserData(res)
    }
  }

  const classesSelectHandler = (value) => {
    setClassesList(value)
  }

  const getExclusionlistHandler = async () => {
    const res = await getExclusionlist()

    setSuggestedList(res.suggestedList)
    setExclutionList(res.companyList)
  }

  const exlutionHandler = (index, item) => {
    // const tempData = [...seggestedList]

    // tempData.splice(index, 1)
    // setExclutionIndex(index)
    // setSuggestedList(tempData)
    postExclusionlistHandler(item)
    getExclusionlistHandler()
    // setExclutionList((prevState) => [...prevState, item])
  }
  const removeHanlder = (index, item) => {
    // const tempData = [...exclutionList]
    // const tempData2 = [...seggestedList]

    // tempData.splice(index, 1)
    // tempData2.splice(exclutionIndex, 0, item)

    // setExclutionList(tempData)
    // setSuggestedList(tempData2)
    deleteExclusionlistHandler(item)
    getExclusionlistHandler()
  }

  const postExclusionlistHandler = async (items) => {
    if (typeof items === 'string' && items === '') {
      setErrorMessageSuccess('Please enter exclusion name')
      openErrorMessageHandler(true)
      return
    }

    const res = await postExclusionlist(items)

    console.log(isEmpty(res), res.response, 'message')
    if (res?.response?.status === 500) {
      setErrorMessageSuccess('Something went wrong')
      openErrorMessageHandler(true)
      return
    }

    openMessageHandler(true)
    setMessageSuccess('Added to EXCLUSION successfully')
    getExclusionlistHandler()

    console.log(res)
  }
  const deleteExclusionlistHandler = async (items) => {
    const res = await deleteExclusionlist(items)
    if (res) {
      openMessageHandler(true)
      setMessageSuccess('removed from EXCLUSION successfully')
    }
    console.log(res)
  }

  const addNewHandler = () => {
    setNewExclusion('')
    postExclusionlistHandler(newExclusion)
  }

  const getNewapplicationsHandler = async () => {
    const res = await getNewapplications()

    if (res && res.length > 0) {
      setNewApplicantList(res)
    }
  }

  const handleClickOpenConfirm = (id) => {
    setOpenConfirm(true)
    setDeleteApplicant(id)
  }

  const postNewapplicationsHandler = async () => {
    if (newApplicantName === '' || classesList.length === 0) {
      openErrorMessageHandler(true)
      setErrorMessageSuccess('All fields are required')
      return
    }
    const res = await postNewapplications(classesList, newApplicantName)

    if (res) {
      setNewApplicantName('')
      classesSelectHandler('')
      openMessageHandler(true)
      setMessageSuccess('New applicatant added successfully')
      getNewapplicationsHandler()
    }
  }

  const deleteNewApplicantHandler = async () => {
    const res = await deleteNewApplicant(deleteApplicant)
    if (res) {
      openMessageHandler(true)
      setMessageSuccess('Applicant deleted successfully')
      getNewapplicationsHandler()
      handleCloseConfirm()
    }
  }

  const updateNewapplicationsHandler = async () => {
    const res = await updateNewapplications(
      editNewApplicantData.classes,
      editNewApplicantData.applicantName,
      editNewApplicantData.id
    )
    openMessageHandler(true)
    setMessageSuccess('Applicant updated successfully')
    getNewapplicationsHandler()
    handleClose()

    console.log(res, 'resDataUpdate')
  }

  const handleEditChange = (e) => {
    console.log(e.target.value)
    const { value } = e.target

    setEditNewApplicantData((prevState) => ({
      ...prevState,
      applicantName: value,
    }))
  }

  const handleEditClassesSelectChange = (value) => {
    setEditNewApplicantData((prevState) => ({ ...prevState, classes: value }))
  }

  useEffect(() => {
    getExclusionlistHandler()
    getNewapplicationsHandler()
    if (localStorageService.getToken()) {
      getUserHandler()
    }
  }, [])

  return (
    <>
      <div className='setting_wrapper'>
        <Container fluid>
          <section className='profile'>
            <h5 className='mb-4 title'>Profile</h5>
            <Row>
              <Col md={8}>
                <h5>{userData.name}</h5>
                <p>
                  <strong> User:</strong> {userData.userId} <br />
                  <strong>Company Name:</strong> {userData.company}
                </p>
              </Col>
              <Col md={4}>
                <Row>
                  <Col md={7}>
                    <div className='form-group mb-0'>
                      <FormControl
                        className={clsx(classes.margin, classes.textField)}
                        variant='filled'>
                        <InputLabel htmlFor='filled-adornment-password'>
                          Password
                        </InputLabel>
                        <FilledInput
                          id='filled-adornment-password'
                          type={values.showPassword ? 'text' : 'password'}
                          value={values.password}
                          required={true}
                          onChange={handleChange('password')}
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge='end'>
                                {values.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {invalidField && (
                          <label className='text-danger'>{errorMessage}</label>
                        )}
                      </FormControl>
                    </div>
                  </Col>
                  <Col md={5} className='d-flex align-items-center'>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={changePasswordHandler}>
                      Update Password
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>

          <section className='new_application'>
            <h5 className='mb-4 title'>New Applications By Applicant</h5>
            <Row>
              <Col md={3}>
                <div className='form-group'>
                  <TextField
                    id='filled-basic'
                    label='Applicant Name'
                    variant='filled'
                    fullWidth
                    onChange={(e) => setNewApplicantName(e.target.value)}
                    value={newApplicantName}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className='form-group d-flex align-items-center'>
                  <Autocomplete
                    fullWidth
                    multiple
                    id='tags-filled'
                    options={classeOption.map((option) => option)}
                    onChange={(e, newValue) => classesSelectHandler(newValue)}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant='outlined'
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='filled'
                        label='Class'
                        placeholder='Add Classes'
                      />
                    )}
                  />
                  <Button
                    className='ml-3'
                    variant='contained'
                    color='primary'
                    onClick={postNewapplicationsHandler}>
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Applicant Name</th>
                  <th>Classes</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {newApplicantList.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td style={{ width: 300 }}>{item.applicantName}</td>
                      <td>
                        {item.classes
                          .map((c, index) => {
                            return c
                          })
                          .join(',')}
                      </td>
                      <td>
                        {' '}
                        <IconButton
                          onClick={() => handleShow(item)}
                          aria-label='delete'
                          className={classes.margin}>
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleClickOpenConfirm(item.id)}
                          aria-label='delete'
                          className={classes.margin}>
                          <DeleteIcon />
                        </IconButton>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </section>

          <section className='suggetion'>
            <div className='head'>
              <Row>
                <Col md={4}>
                  <h5 className='title'>
                    Exclusion List{' '}
                    <IconButton
                      onClick={getExclusionlistHandler}
                      aria-label='delete'
                      className={classes.margin}>
                      <RefreshIcon />
                    </IconButton>
                  </h5>
                </Col>
                <Col md={4}>
                  <div className='form-group'>
                    <TextField
                      id='filled-basic'
                      label='Add New'
                      variant='filled'
                      onChange={(e) => setNewExclusion(e.target.value)}
                      value={newExclusion}
                    />
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={addNewHandler}>
                      Add
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              <Col md={4}>
                <h5>Suggested List</h5>

                {seggestedList?.length > 0 ? (
                  <ul className='suggestion_ul'>
                    {seggestedList.map((p, i) => {
                      return (
                        <li key={i} onClick={() => exlutionHandler(i, p)}>
                          {p} <ArrowForwardIosIcon />
                        </li>
                      )
                    })}
                  </ul>
                ) : (
                  'No Data Found'
                )}
              </Col>
              <Col md={4}>
                <h5>EXCLUSION LIST </h5>
                {exclutionList?.length > 0 ? (
                  <ul>
                    {exclutionList.map((p, i) => {
                      return (
                        <li onClick={() => removeHanlder(i, p)}>
                          <ArrowBackIosIcon /> {p}
                        </li>
                      )
                    })}
                  </ul>
                ) : (
                  'No Data Found'
                )}

                {/* {exclutionList.length > 0 && (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={postExclusionlistHandler}>
                    Submit
                  </Button>
                )} */}
              </Col>
            </Row>
          </section>
        </Container>
      </div>
      {open && (
        <Alert severity='success'>
          Your password has been successfully changed
        </Alert>
      )}
      <Snackbar
        open={openMessage}
        autoHideDuration={6000}
        onClose={closeMessageHandler}>
        <Alert onClose={closeMessageHandler} severity='success'>
          {messageSuccess}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openMessageError}
        autoHideDuration={6000}
        onClose={closeErrorMessageHandler}>
        <Alert onClose={closeErrorMessageHandler} severity='error'>
          {errorMessageSuccess}
        </Alert>
      </Snackbar>
      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          {'Do you really want to delete?'}
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleCloseConfirm} color='primary'>
            No
          </Button>
          <Button color='primary' autoFocus onClick={deleteNewApplicantHandler}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Applicant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <div className='form-group'>
                <TextField
                  id='filled-basic'
                  label='Applicant Name'
                  variant='filled'
                  fullWidth
                  value={editNewApplicantData.applicantName}
                  defaultValue={editNewApplicantData.applicantName}
                  onChange={handleEditChange}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className='form-group '>
                <Autocomplete
                  fullWidth
                  multiple
                  id='tags-filled'
                  options={classeOption.map((option) => option)}
                  onChange={(e, newValue) =>
                    handleEditClassesSelectChange(newValue)
                  }
                  defaultValue={editNewApplicantData.classes}
                  freeSolo
                  renderTags={(value, getTagProps) => {
                    console.log(value, 'optionValue')
                    let tempValue = value
                    return tempValue.map((option, index) => (
                      <Chip
                        variant='outlined'
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='filled'
                      label='Class'
                      placeholder='Add Classes'
                    />
                  )}
                />
              </div>
              <Button
                variant='contained'
                color='primary'
                onClick={updateNewapplicationsHandler}>
                Submit
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Settings
