/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// ES6
import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react'

import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Button,
  Snackbar,
  InputAdornment,
  IconButton,
  Checkbox,
} from '@material-ui/core/'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import FastRewindIcon from '@material-ui/icons/FastRewind'
import { Table, Form } from 'react-bootstrap/'
import MarkEditInput from './MarkEditInput'
import EditIcon from '@material-ui/icons/Edit'
import SearchIcon from '@material-ui/icons/Search'
import MuiAlert from '@material-ui/lab/Alert'

import config from '../../../environment'
import { useHistory } from 'react-router-dom'
import { AppContext } from '../../../context'
import { RowSelectContext } from '../../../context/RowSelectContext'
import { getAllJournals } from '../WordMark/WordMarkService'
import {
  getAllLogoMarksFromApi,
  updateMark,
  getSearchedLogoMarkData,
} from './LogoMarkService'
import PdfControl from '../PDF/PdfControl'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import LocalStorageService from '../../../LocalStorageService'

import Loader from '../../includes/Loader'
import Pagination from '@material-ui/lab/Pagination'
import LogoTable from './Table'

const localStorageService = LocalStorageService.getService()

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const classeOption = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  99,
  'All',
]

let rowSelectedList = []
let pageCountObj = 0
let isNextClick = true
const LogoMark = () => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const context = useContext(AppContext)
  const contextRowSelect = useContext(RowSelectContext)

  const {
    setAuthVisible,

    setSearchedLogoMarkData,
    setComparisionPdfData,
    setMarkUpdateContext,
    setLogoMarkDataContext,
    setJournalContext,
  } = context
  const { setRowSelectedDataContext } = contextRowSelect
  let history = useHistory()
  const [logoMarks, setLogoMarks] = useState([])
  const [journalData, setJournalData] = useState([])
  const [journal, setJournal] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [currentRowData, setCurrentRowData] = useState([])
  const [classNumber, setClassNumber] = useState(1)
  const [journalType, setJournalType] = useState('logo')
  const [markUpdate, setMarkUpdate] = useState({})
  const [isAdmin, setRoleAdmin] = useState(false)
  const [open, setOpen] = React.useState(false)
  const [errorVisible, setErrorVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [updatedLogoMarks, setUpdateLogoMark] = useState('')
  const [rowSelectedData, setRowSelectedData] = useState([])
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [page, setPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [openError, setOpenError] = React.useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [selectedValue, setSelectedValue] = useState('applicationNo')
  const [pageId, setPageId] = useState([])
  const [endOfData, setEndOfData] = useState(false)

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenError(false)
  }

  const handleValueChange = (event) => {
    setSelectedValue(event.target.value)
  }

  const handleChange = (event) => {
    setIsLoading(true)
    const selectedValue = event.target.value
    setJournal(selectedValue)
    setJournalContext(selectedValue)
    rowSelectedList = []

    // pageCountObj = 0
    // isNextClick = true
    // localStorage.removeItem('pageId')
    // localStorage.removeItem('totalPageId')
    setPage(1)
    getAllLogoMarkList(selectedValue, classNumber, 1, journalType)
    setRowSelectedDataContext(rowSelectedList)
  }

  const handleChangeClass = (event) => {
    setIsLoading(true)
    const selectedValue = event.target.value

    rowSelectedList = []
    // pageCountObj = 0
    // isNextClick = true
    // localStorage.removeItem('pageId')
    // localStorage.removeItem('totalPageId')
    setPage(1)
    setClassNumber(selectedValue)
    getAllLogoMarkList(journal, selectedValue, 1, journalType)
    setRowSelectedDataContext(rowSelectedList)
  }
  const handlePageChange = (event, value) => {
    setIsLoading(true)
    setPage(value)
    getAllLogoMarkList(journal, classNumber, value, journalType)
  }
  const handlePageNextChange = () => {
    isNextClick = true

    if (endOfData) {
      return
    }

    getAllLogoMarkList(
      journal,
      classNumber,
      journalType,
      `&page=${pageId[pageCountObj]}`
    )

    localStorage.setItem('pageId', pageId[pageCountObj])
    localStorage.setItem('totalPageId', JSON.stringify(pageId))

    pageCountObj++
  }
  const handlePagePrevChange = () => {
    isNextClick = false
    pageCountObj--

    if (pageCountObj <= 0) {
      getAllLogoMarkList(journal, classNumber, journalType)
      localStorage.removeItem('pageId')
      localStorage.removeItem('totalPageId')
    } else {
      getAllLogoMarkList(
        journal,
        classNumber,
        journalType,
        `&page=${pageId[pageCountObj - 1]}`
      )
    }
    const tempPageId = [...pageId]
    tempPageId.splice(pageCountObj + 1, 1)
    setPageId(tempPageId)
    setEndOfData(false)
  }
  const backToPageOneHandler = () => {
    getAllLogoMarkList(journal, classNumber, journalType)
    setPageId([])
    pageCountObj = 0
  }

  const handleChangeJournalType = (event) => {
    setIsLoading(true)
    const selectedValue = event.target.value
    rowSelectedList = []
    // pageCountObj = 0
    // isNextClick = true
    // localStorage.removeItem('pageId')
    // localStorage.removeItem('totalPageId')
    setPage(1)
    setJournalType(selectedValue)
    getAllLogoMarkList(journal, classNumber, 1, selectedValue)
    setRowSelectedDataContext(rowSelectedList)
  }

  const getAllLogoMarkList = useCallback(
    async (journalObj, classObj, journalTypeObj, pageObj) => {
      console.log(journalObj, classObj, journalTypeObj, pageObj, 'pageObj')
      setIsLoading(true)
      const res = await getAllLogoMarksFromApi(
        journalObj,
        classObj === 'All' ? 'all' : classObj,
        journalTypeObj,
        pageObj
      )

      console.log(res, 'resresresres')

      const logoMarkItems = res.Items

      if (logoMarkItems && logoMarkItems.length > 0) {
        logoMarkItems.forEach((element) => {
          element.isLoading = false
          if (element.mark) {
            element.isMarked = true
          } else {
            element.isMarked = false
          }
        })

        setLogoMarks(logoMarkItems)
        setComparisionPdfData('journalPdfData', logoMarkItems)
        setLogoMarkDataContext(logoMarkItems)
        setPageCount(res.Count)
        // if (isNextClick) {
        //   setPageId((prevState) => [...prevState, res.PageId])
        //   if (res.PageId) {
        //     setEndOfData(false)
        //   } else {
        //     setEndOfData(true)
        //   }
        // }
        setIsLoading(false)
      } else {
        setLogoMarks([])
        setIsLoading(false)
      }
    },
    [setLogoMarkDataContext]
  )

  const getJournals = useCallback(async () => {
    const res = await getAllJournals(localStorageService.getCompanyId())
    if (res.length > 0) {
      setJournalData(res)
      setTimeout(() => {
        setJournal(res[0])
        setJournalContext(res[0])
        getAllLogoMarkList(res[0], classNumber, page, journalType)

        // if (localStorage.getItem('pageId')) {
        //   getAllLogoMarkList(
        //     res[0],
        //     classNumber,
        //     journalType,
        //     `&page=${localStorage.getItem('pageId')}`
        //   )
        // } else {

        // }
      }, 200)
    }
  }, [classNumber, getAllLogoMarkList, journalType, page])

  useEffect(() => {
    if (localStorageService.getToken()) {
      setAuthVisible(false)
      setTimeout(() => {
        getJournals()
      }, 1000)
    } else {
      history.push('/login')
    }
    if (localStorageService.getUserRole() === 'admin') {
      setRoleAdmin(true)
    }
    return () => {
      setRowSelectedDataContext([])
      rowSelectedList = []
      setComparisionPdfData('journalPdfData', [])
      // pageCountObj = 0
      // isNextClick = true
      // localStorage.removeItem('pageId')
      // localStorage.removeItem('totalPageId')
    }
  }, [])

  const handleSearch = (e) => {
    const searchValue = e.target.value.trim()

    setSearchQuery(searchValue)
    if (searchValue.length === 0) {
      setIsLoading(true)
      setCurrentRowData([])
      getAllLogoMarkList(journal, classNumber, page, journalType)
      // pageCountObj = 0
      // isNextClick = true
      // localStorage.removeItem('pageId')
      // localStorage.removeItem('totalPageId')
    }
  }

  const getSearchedData = async (valueObj) => {
    let isValid = false
    const isNum = /^\d+$/.test(valueObj)
    if (!isNum && selectedValue === 'applicationNo') {
      setOpenError(true)
      setErrorMsg('Please enter number')
      isValid = false
      return
    } else if (isNum) {
      isValid = true
    } else if (!isNum) {
      isValid = true
    } else if (isNum && selectedValue === 'mark') {
      setOpenError(true)
      setErrorMsg('Please enter mark')
    }

    if (isValid) {
      setIsLoading(true)
      const resData = await getSearchedLogoMarkData(
        journal,
        journalType,
        classNumber === 'All' ? 'all' : classNumber,
        selectedValue,
        valueObj
      )
      const logoMarkDataItems = resData.Items

      if (logoMarkDataItems) {
        if (logoMarkDataItems.length > 0) {
          logoMarkDataItems.forEach((element) => {
            element.isLoading = false
            if (element.mark) {
              element.isMarked = true
            } else {
              element.isMarked = false
            }
          })
          setComparisionPdfData('journalPdfData', logoMarkDataItems)
          setLogoMarks(logoMarkDataItems)
          setLogoMarkDataContext(logoMarkDataItems)
          setPageCount(resData.Count)

          setIsLoading(false)
          // pageCountObj = 0
          // isNextClick = true
          // localStorage.removeItem('pageId')
        } else {
          setLogoMarks([])
          setIsLoading(false)
        }
      }
    }
  }

  const searchHandler = () => {
    getSearchedData(searchQuery)
  }

  const rowSelectHandler = (appNo, e) => {
    if (e.target.checked) {
      const selectedRow = logoMarks.find(
        (p) => p.applicationNo === appNo.applicationNo
      )
      rowSelectedList.push({ ...selectedRow })

      setRowSelectedData(rowSelectedList)
      setRowSelectedDataContext(rowSelectedList)
    } else {
      const rowIndex = rowSelectedList.findIndex(
        (p) => p.applicationNo === appNo.applicationNo
      )
      rowSelectedList.splice(rowIndex, 1)
      setRowSelectedDataContext(rowSelectedList)
    }
  }

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  const editMarkHandler = (id, value) => {
    const editedData = logoMarks.find((p) => p.applicationNo === id)

    setUpdateLogoMark({ ...markUpdate, [`value_${id}`]: editedData.mark })
    setMarkUpdate({ ...markUpdate, [`value_${id}`]: editedData.mark })

    logoMarks.forEach((mark) => {
      if (mark.applicationNo === id) {
        mark.isMarked = false
      }
    })
  }

  function isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false
    }

    return true
  }
  const onSubmitHandler = async (applicationNoObj) => {
    if (isEmpty(markUpdate)) {
      return
    }

    const updateObj = {
      applicationNo: applicationNoObj,
      mark: markUpdate[`value_${applicationNoObj}`]
        ? markUpdate[`value_${applicationNoObj}`]
        : document.getElementById(`value_${applicationNoObj}`).value,
    }
    logoMarks.forEach((mark) => {
      if (mark.applicationNo === applicationNoObj) {
        mark.isLoading = true
      }
    })

    setBtnDisabled(true)

    if (markUpdate) {
      const res = await updateMark(journal, updateObj)

      if (res.mark) {
        setMarkUpdateContext({})
        setOpen(true)
        setErrorVisible(false)

        // getAllLogoMarkList(journal, classNumber, page, journalType)
        logoMarks.forEach((mark) => {
          if (mark.applicationNo === applicationNoObj) {
            mark.isLoading = false
            mark.mark = res.mark
            mark.isMarked = true
          }
        })
        setBtnDisabled(false)
      } else {
        setOpen(true)
        setErrorVisible(true)
        setErrorMessage('Something went wrong!')
        setIsLoading(false)
        setBtnDisabled(false)
        logoMarks.forEach((element) => {
          element.isLoading = false
        })
        setLogoMarks(logoMarks)
      }
    } else {
      setOpen(true)
      setErrorVisible(true)
      setErrorMessage('  Please fill the mark first')
      setBtnDisabled(false)
    }
  }

  const updateMarkChangeHandler = useCallback(
    (event) => {
      const { id, value } = event.target
      setMarkUpdate({ ...markUpdate, [id]: value })
    },
    [markUpdate]
  )

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const totalPageIdCount = JSON.parse(localStorage.getItem('totalPageId'))
  console.log(totalPageIdCount, 'totalPageIdCount')

  return (
    <>
      <div className='top_head_table'>
        <div className='select_wrap'>
          <FormControl variant='filled' fullWidth>
            <InputLabel id='demo-simple-select-outlined-label'>
              Select Journal
            </InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              value={journal}
              onChange={handleChange}
              label='Journal'>
              {journalData.map((j, i) => (
                <MenuItem value={j} key={i}>
                  {j}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant='filled' fullWidth>
            <InputLabel id='demo-simple-select-outlined-label'>
              Class
            </InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              onChange={handleChangeClass}
              value={classNumber}
              label='Class'>
              {classeOption.map((c, i) => (
                <MenuItem value={c} key={i}>
                  {c}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant='filled' fullWidth>
            <InputLabel id='demo-simple-select-outlined-label'>Type</InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              onChange={handleChangeJournalType}
              value={journalType}
              label='Type'>
              <MenuItem value='all'>All</MenuItem>
              <MenuItem value='logo'>Logo</MenuItem>
              <MenuItem value='word'>Word</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className='search_wrap'>
          <TextField
            id='filled-basic'
            label='Search'
            variant='filled'
            className='search_box'
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={searchHandler}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControl variant='filled' className='ml-1 select_column'>
            <InputLabel id='demo-simple-select-label'>Select column</InputLabel>
            <Select
              labelId='demo-simple-select-filled-label'
              id='demo-simple-select-filled'
              value={selectedValue}
              onChange={handleValueChange}>
              <MenuItem value='applicationNo'>Application No</MenuItem>
              <MenuItem value='mark'>Mark</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className='pagination_wrap'>
          <Pagination
            count={pageCount}
            page={page}
            color='primary'
            onChange={handlePageChange}
          />
          {/* <ul>
            {totalPageIdCount?.length > 0 && (
              <>
                {' '}
                <li>
                  <IconButton
                    aria-label='delete'
                    onClick={backToPageOneHandler}>
                    <FirstPageIcon fontSize='large' />
                  </IconButton>
                </li>
                <li>
                  <IconButton
                    aria-label='delete'
                    onClick={handlePagePrevChange}>
                    <NavigateBeforeIcon fontSize='large' />
                  </IconButton>
                </li>
              </>
            )}

            <li className={endOfData ? 'no_next' : ''}>
              {' '}
              <IconButton aria-label='delete' onClick={handlePageNextChange}>
                <NavigateNextIcon fontSize='large' />
              </IconButton>
            </li>
          </ul> */}
        </div>
      </div>
      {isLoading && <Loader />}
      <div className='table_body_wrap'>
        <div className='title_head'>
          <h5>Journal</h5>
          <PdfControl exportToCSV={exportToCSV} />
        </div>

        {/* <LogoTable
          logoMarks={logoMarks}
          rowSelectedList={rowSelectedList}
          rowSelectHandler={rowSelectHandler}
          journal={journal}
          isAdmin={isAdmin}
          editMarkHandler={editMarkHandler}
          onSubmitHandler={onSubmitHandler}
          btnDisabled={btnDisabled}
          journalType={journalType}
        /> */}
        <Table striped bordered hover size='sm'>
          <thead>
            <tr>
              <th></th>
              {journalType === 'word' ? null : <th>Logo</th>}

              <th>Class</th>
              <th>Application Date</th>
              <th>Page</th>
              <th>Application No</th>
              <th>Mark</th>
            </tr>
          </thead>
          <tbody>
            {logoMarks.length > 0 ? (
              <>
                {' '}
                {logoMarks.map((logo, i) => (
                  <tr key={i}>
                    <td>
                      <Checkbox
                        checked={rowSelectedList.some((p) =>
                          p.applicationNo === logo.applicationNo ? 1 : 0
                        )}
                        color='primary'
                        onClick={(e) => rowSelectHandler(logo, e)}
                        inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                      />
                    </td>
                    {journalType === 'word' ? null : (
                      <td>
                        {logo.url ? (
                          <img className='logo_mark' alt='' src={logo.url} />
                        ) : null}
                      </td>
                    )}

                    <td>{logo.class}</td>
                    <td>{logo.applicationDate}</td>
                    <td>{logo.page}</td>
                    <td>
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href={`${config.PDF_URL}/${journal}/pages/${logo.applicationNo}.pdf`}>
                        {logo.applicationNo}
                      </a>
                    </td>
                    <td>
                      {logo.isMarked ? (
                        <div className='edit_wrap'>
                          {logo.mark}{' '}
                          {isAdmin && (
                            <>
                              {' '}
                              <span
                                className='edit_mark'
                                onClick={() =>
                                  editMarkHandler(logo.applicationNo, logo.mark)
                                }>
                                <EditIcon />
                              </span>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className='update_wrap'>
                          {isAdmin && (
                            <>
                              {logo.isLoading ? (
                                <div className='loader__wrap'>
                                  <div className='loadingio-spinner-dual-ball-xv37mtfudqd'>
                                    <div className='ldio-hfjav9dlpac'>
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <Form.Control
                                    type='text'
                                    id={`value_${logo.applicationNo}`}
                                    placeholder='Mark'
                                    onChange={(e) => updateMarkChangeHandler(e)}
                                    value={
                                      markUpdate[`value_${logo.applicationNo}`]
                                    }
                                  />
                                  <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() =>
                                      onSubmitHandler(logo.applicationNo)
                                    }
                                    disabled={logo.isLoading}>
                                    Submit
                                  </Button>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan='8'>Sorry no record found!</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        {errorVisible ? (
          <Alert onClose={handleClose} severity='error'>
            {errorMessage}
          </Alert>
        ) : (
          <Alert onClose={handleClose} severity='success'>
            Mark updated successfully!
          </Alert>
        )}
      </Snackbar>
    </>
  )
}

export default LogoMark
