import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { AppProvider } from './context'
import { RowSelectProvider } from './context/RowSelectContext'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './theme'
import axios from 'axios'
import LocalStorageService from './LocalStorageService'
import * as serviceWorker from './serviceWorker'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.delete['Content-Type'] = 'application/json'

axios.interceptors.request.use(
  (reqConfig) => {
    const reqConfiglocal = reqConfig
    const token = LocalStorageService.getToken()
    if (token) {
      reqConfiglocal.headers.Authorization = `${token}`
    }

    return reqConfiglocal
  },
  (error) => {
    Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.log(error, 'error')

    return Promise.reject(error)
  }
)
ReactDOM.render(
  <AppProvider>
    <RowSelectProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </RowSelectProvider>
  </AppProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
