/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'

import { AppContext } from '../../context'
import Logo from '../../assets/img/ipbloc.png'
import Header from '../includes/Header'
import Footer from '../includes/Footer'
import ContactModal from '../Auth/ContactModal'

/**
 * @author
 * @class Home
 **/

class Home extends Component {
  state = {
    showContactModal: false,
  }

  handleShowContact = () => {
    this.setState({
      showContactModal: true,
    })
  }
  handleCloseContact = () => {
    this.setState({
      showContactModal: false,
    })
  }
  render() {
    const { showContactModal } = this.state
    return (
      <>
        <Header />
        <header className='fixed-top-added'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-6 col-md-7'>
                <div className='hero-content'>
                  <h1 className='text-primary'>
                    <span>
                      <img style={{ height: 66 }} alt="logo" src={Logo} className="logo_img" />
                      <strong>TM</strong>SPY
                    </span>{' - '}TM Watch using AI
                  </h1>
                  {<span className="text-primary mt-3">
                    Protect your brand from deceptively similar marks published in the journal through automated reports every week​.
                  </span>}
                  <ul className='pair-btns-list'>
                    <li>
                    <a
                        href='javascript:void(0)'
                        className='cbtn btn-grad btn-round btn-width'
                        onClick={this.handleShowContact}>
                        I want a demo
                      </a>{' '}
                        <a
                          href='javascript:void(0)'
                          className='cbtn btn-grad-s btn-width btn-round'
                          onClick={this.handleShowContact}>
                          I want to try
                        </a>
                    </li>
                    {/* <li>
                      {' '}
                      <a
                        className="cbtn btn-grad-s btn-shadow btn-width venobox vbox-item video-play"
                        data-autoplay="true"
                        data-vbtype="video"
                        href="https://youtu.be/kubGCSj5y3k"
                      >
                        Live Demo
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className='col-sm-6 col-md-5'>
                <video controls width="550" height="300" autoPlay = {true} loop={true}>
                  <source src="/images/video1.mp4" type="video/mp4"/>
                  Sorry, your browser doesn't support embedded videos.
                </video>
                
              </div>
            </div>
          </div>
        </header>

        <div className='bg-gray py-80'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='section-head text-center'>
                  <h1 className='text-primary'>
                    Why{' '}
                    <span className='why_tm'>
                      <strong>TM</strong>SPY
                    </span>{' '}
                    ?
                  </h1>
                  <p className='text-secondary'>{/*Put some text if needed*/}</p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12'>
                <ul
                  className='nav nav-pills material-pills nav-border-tabs nav-fill'
                  id='pills-tab'
                  role='tablist'>
                  <li className='nav-item'>
                    <a
                      className='nav-link active'
                      id='cs-pills-one-tab'
                      data-toggle='pill'
                      href='#cs-pills-one'
                      role='tab'
                      aria-controls='cs-pills-one'
                      aria-selected='true'>
                      Power of Tech
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      id='cs-pills-two-tab'
                      data-toggle='pill'
                      href='#cs-pills-two'
                      role='tab'
                      aria-controls='cs-pills-two'
                      aria-selected='false'>
                      Automated Reports
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      id='cs-pills-three-tab'
                      data-toggle='pill'
                      href='#cs-pills-three'
                      role='tab'
                      aria-controls='cs-pills-three'
                      aria-selected='false'>
                      Efficiency
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      id='cs-pills-five-tab'
                      data-toggle='pill'
                      href='#cs-pills-five'
                      role='tab'
                      aria-controls='cs-pills-five'
                      aria-selected='false'>
                      Client Satisfaction
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      id='cs-pills-six-tab'
                      data-toggle='pill'
                      href='#cs-pills-six'
                      role='tab'
                      aria-controls='cs-pills-six'
                      aria-selected='false'>
                      Revenue generation
                    </a>
                  </li>
                </ul>
                <div
                  className='tab-content mt-5 feature_tabs'
                  id='pills-tabContent'>
                  <div
                    className='tab-pane fade show active'
                    id='cs-pills-one'
                    role='tabpanel'
                    aria-labelledby='cs-pills-one-tab'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='material-pills-text l-text'>
                          <h4 className='text-primary'>Power of Tech</h4>
                          <p>
                            Watch and protect your clients' brands on real time 
                            basis with ease and minimum human interference. 
                            So while you focus on your business, our powerful AI algorithms 
                            will take care of the rest.
                          </p>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <img
                          src='img/power-of-tech.png'
                          className='img-fluid'
                          alt=''
                          data-aos='fade-up'
                          data-aos-anchor-placement='top-bottom'
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='cs-pills-two'
                    role='tabpanel'
                    aria-labelledby='cs-pills-two-tab'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <img src='img/power.jpg' className='img-fluid' alt='' />
                      </div>
                      <div className='col-sm-6'>
                        <div className='material-pills-text'>
                          <h4 className='text-primary'>Automated Reports</h4>
                          <p>
                            Get useful insights from thousands of trade marks
                            published in the journal every week through
                            automated reports
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='cs-pills-three'
                    role='tabpanel'
                    aria-labelledby='cs-pills-three-tab'>
                    <div className='row'>
                      <div className='col-md-6 '>
                        <div className='material-pills-text'>
                          <h4 className='text-primary'>Efficiency</h4>
                          <p>
                            Save tons of man hours on going through journal
                            manually and remembering the brand names. Rather
                            focus on being productive and efficient.
                          </p>
                        </div>
                      </div>
                      <div className=' col-md-6'>
                        <img
                          src='img/efficiency.png'
                          className='img-fluid'
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='cs-pills-five'
                    role='tabpanel'
                    aria-labelledby='cs-pills-five-tab'>
                    <div className='row'>
                      <div className='col-md-6 '>
                        <div className='material-pills-text'>
                          <h4 className='text-primary'>Client Satisfaction</h4>
                          <p>
                            Never miss on filing any opposition and protect your
                            clients' brands. Engage early and take quick action.
                          </p>
                        </div>
                      </div>
                      <div className='col-md-6 '>
                        <img src='img/client.jpg' className='img-fluid' alt='' />
                      </div>
                    </div>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='cs-pills-six'
                    role='tabpanel'
                    aria-labelledby='cs-pills-six-tab'>
                    <div className='row'>
                      <div className='col-md-6 '>
                        <div className='material-pills-text'>
                          <h4 className='text-primary'>Revenue generation</h4>
                          <p>
                            Increase your revenue by focusing on what matters
                            most.
                          </p>
                        </div>
                      </div>
                      <div className='col-md-6 '>
                        <img src='img/revenue.jpg' className='img-fluid' alt='' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=' py-80 features' id='features'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='section-head text-center'>
                  <h1 className='text-primary'>Product Features</h1>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-4'>
                <div
                  className='cs-infobox-left mt-4'
                  data-aos='fade-up'
                  data-aos-anchor-placement='top-bottom'
                  data-aos-duration='1000'>
                  <i className='fas fa-equals'></i> <i className='fas fa-not-equal'></i>
                  ​<h4 className='text-primary'>Comparisons </h4>
                  <p>
                    Side by side comparison of similar and identical marks
                    including phonetics.
                  </p>
                </div>
              </div>
              <div className='col-md-4'>
                <div
                  className='cs-infobox-left mt-4'
                  data-aos='fade-up'
                  data-aos-anchor-placement='top-bottom'
                  data-aos-duration='1200'>
                  <i className='fas fa-file-pdf'></i>​
                  <h4 className='text-primary'>Reports </h4>
                  <p>
                    Export reports of deceptively similar marks and share it
                    with your team/clients.
                  </p>
                </div>
              </div>
              <div className='col-md-4'>
                <div
                  className='cs-infobox-left mt-4'
                  data-aos='fade-up'
                  data-aos-anchor-placement='top-bottom'
                  data-aos-duration='1400'>
                  <i className='fas fa-list-alt'></i> <i className='fas fa-search'></i>​
                  <h4 className='text-primary'>Journal with Search</h4>
                  <p>
                    View the whole journal class wise along with filter based
                    search.
                  </p>
                </div>
              </div>
              <div className='col-md-4'>
                <div
                  className='cs-infobox-left mt-4'
                  data-aos='fade-up'
                  data-aos-anchor-placement='top-bottom'
                  data-aos-duration='1600'>
                  <i className='fas fa-trademark'></i> <i className='fas fa-upload'></i>
                  ​<h4 className='text-primary'>Published Marks</h4>
                  <p>
                    No need to manually search your own published marks. IPBLOC
                    TMSPY will provide you the list of your client's published
                    marks every week.
                  </p>
                </div>
              </div>
              <div className='col-md-4'>
                <div
                  className='cs-infobox-left mt-4'
                  data-aos='fade-up'
                  data-aos-anchor-placement='top-bottom'
                  data-aos-duration='1800'>
                  <i className='fas fa-exclamation-circle'></i>​
                  <h4 className='text-primary'>Notice </h4>
                  <p>
                    Don't miss out on any notices published in the journal.
                    Corrigenda, Notifications, Registered, Renewals and PR
                    Section.
                  </p>
                </div>
              </div>
              <div className='col-md-4'>
                <div
                  className='cs-infobox-left mt-4'
                  data-aos='fade-up'
                  data-aos-anchor-placement='top-bottom'
                  data-aos-duration='2000'>
                  <i className='fas fa-eye'></i>​
                  <h4 className='text-primary'>New Applications Monitoring</h4>
                  <p>
                    Get reports for new applications by brand or applicant name.
                    Send legal notices to new applications applied within last 7
                    days.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className='bg-gray py-40'>
          <div className='container'>
            <div className='call-action-media'>
              <div className='row'>
                <div className='col-sm-8'>
                  <div className='media'>
                    <div className='media-left'>
                      {' '}
                      <img
                        src='img/join-us.png'
                        alt='img'
                        className='img-fluid'
                        width='90'
                      />{' '}
                    </div>
                    <div className='media-body'>
                      <h3 className='text-primary'>Try our product for Free?</h3>
                      <p className='call-action-media-text'>
                        Try our product for 14 days with all the features
                        mentioned above
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-sm-4'>
                  <div className='mt-4'>
                    <a
                      href='javascript:void(0)'
                      className='cbtn btn-grad btn-width btn-round'
                      onClick={this.handleShowContact}>
                      I want to try
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="py-80 bg-gray">
          <div className="box-shadow custom-witdth-box">
            <div className="floated-box">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <a
                      className="venobox vbox-item video-play"
                      data-autoplay="true"
                      data-vbtype="video"
                      href="https://youtu.be/kubGCSj5y3k"
                    >
                      <img src="images/video-bg.png" alt="" className="img-fluid" />
                      <div className="vid-fixed-icn">
                        <img
                          src="images/video-thumb.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="slider single-item">
                      <div className="slider-item">
                        <div className="testimonial-default">
                          <div className="testimonial-default-img">
                            <img
                              src="images/female-75.jpg"
                              alt=""
                              className="center-block img-fluid"
                            />
                          </div>
                          <h4>
                            “On refusa continuar payar custosi traductores. At
                            solmen va esser necessi far uniform grammatica,
                            pronunciation plu sommun paroles.”
                          </h4>
                          <h5>Martin Loyola - HR at SofTech</h5>
                        </div>
                      </div>
                      <div className="slider-item">
                        <div className="testimonial-default">
                          <div className="testimonial-default-img">
                            <img
                              src="images/male-14.jpg"
                              alt=""
                              className="center-block img-fluid"
                            />
                          </div>
                          <h4>
                            “On refusa continuar payar custosi traductores. At
                            solmen va esser necessi far uniform grammatica,
                            pronunciation plu sommun paroles.”
                          </h4>
                          <h5>Martin Loyola - HR at SofTech</h5>
                        </div>
                      </div>
                      <div className="slider-item">
                        <div className="testimonial-default">
                          <div className="testimonial-default-img">
                            <img
                              src="images/29.jpg"
                              alt=""
                              className="center-block img-fluid"
                            />
                          </div>
                          <h4>
                            “On refusa continuar payar custosi traductores. At
                            solmen va esser necessi far uniform grammatica,
                            pronunciation plu sommun paroles.”
                          </h4>
                          <h5>Martin Loyola - HR at SofTech</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="py-80" id="pricing">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-head text-center">
                <h1 className="text-primary">Best Pricing Plan</h1>
                <p>
                  Close more deals than ever, automatice lead captures,in-built
                  phone,smart alerts with push notifcations.
                </p>
              </div>
              <ul
                className="nav nav-pills justify-content-center nav-pills-group"
                id="pills-tab2"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="pills-home-tab2"
                    data-toggle="pill"
                    href="#pills-home2"
                    role="tab"
                    aria-controls="pills-home2"
                    aria-selected="true"
                  >
                    Monthly
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="pills-profile-tab2"
                    data-toggle="pill"
                    href="#pills-profile2"
                    role="tab"
                    aria-controls="pills-profile2"
                    aria-selected="false"
                  >
                    Yearly
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent2">
                <div
                  className="tab-pane fade show active"
                  id="pills-home2"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab2"
                >
                  <div className="row">
                    <div className="col-md-4">
                      <div
                        className="cs-price-card mt-5"
                        data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <div className="cs-price-card-info">
                          <h5>Free</h5>
                          <h1>
                            <sup>$</sup>0.00<small>Month</small>
                          </h1>
                        </div>
                        <div className="cs-price-card-content">
                          <ul className="cs-price-card-features">
                            <li>1 - 5 Users</li>
                            <li>5 GB Upload Size</li>
                            <li>Full Customer Support</li>
                            <li className="in-active">Free Updates</li>
                            <li className="in-active">Maximum Protection</li>
                          </ul>
                          <div>
                            <a href="#" className="btn btn-light btn-round btn-sm">
                              Get Started
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className="cs-price-card mt-5"
                        data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom"
                        data-aos-duration="1300"
                      >
                        <div className="cs-price-card-info">
                          <h5>Premium</h5>
                          <h1>
                            <sup>$</sup>12.00<small>Month</small>
                          </h1>
                        </div>
                        <div className="cs-price-card-content">
                          <ul className="cs-price-card-features">
                            <li>1 - 5 Users</li>
                            <li>5 GB Upload Size</li>
                            <li>Full Customer Support</li>
                            <li>Free Updates</li>
                            <li className="in-active">Maximum Protection</li>
                          </ul>
                          <div>
                            <a href="#" className="cbtn btn-grad btn-round btn-sm">
                              Get Started
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className="cs-price-card mt-5"
                        data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom"
                        data-aos-duration="1800"
                      >
                        <div className="cs-price-card-info">
                          <h5>Advanced</h5>
                          <h1>
                            <sup>$</sup>28.00<small>Month</small>
                          </h1>
                        </div>
                        <div className="cs-price-card-content">
                          <ul className="cs-price-card-features">
                            <li>1 - 5 Users</li>
                            <li>5 GB Upload Size</li>
                            <li>Full Customer Support</li>
                            <li>Free Updates</li>
                            <li>Maximum Protection</li>
                          </ul>
                          <div>
                            <a href="#" className="btn btn-light btn-round btn-sm">
                              Get Started
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile2"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab2"
                >
                  <div className="row">
                    <div className="col-md-4">
                      <div className="cs-price-card mt-5">
                        <div className="cs-price-card-info">
                          <h5>Silver</h5>
                          <h1>
                            <sup>$</sup>40.00<small>Month</small>
                          </h1>
                        </div>
                        <div className="cs-price-card-content">
                          <ul className="cs-price-card-features">
                            <li>1 - 5 Users</li>
                            <li>5 GB Upload Size</li>
                            <li>Full Customer Support</li>
                            <li className="in-active">Free Updates</li>
                            <li className="in-active">Maximum Protection</li>
                          </ul>
                          <div>
                            <a href="#" className="btn btn-light btn-round btn-sm">
                              Get Started
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="cs-price-card mt-5">
                        <div className="cs-price-card-info btn-grad">
                          <h5 className="text-white">Diamond</h5>
                          <h1 className="text-white">
                            <sup>$</sup>212.00<small>Month</small>
                          </h1>
                        </div>
                        <div className="cs-price-card-content">
                          <ul className="cs-price-card-features">
                            <li>1 - 5 Users</li>
                            <li>5 GB Upload Size</li>
                            <li>Full Customer Support</li>
                            <li>Free Updates</li>
                            <li className="in-active">Maximum Protection</li>
                          </ul>
                          <div>
                            <a
                              href="#"
                              className="btn btn-primary btn-round btn-sm"
                            >
                              Get Started
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="cs-price-card mt-5">
                        <div className="cs-price-card-info">
                          <h5>Gold</h5>
                          <h1>
                            <sup>$</sup>128.00<small>Month</small>
                          </h1>
                        </div>
                        <div className="cs-price-card-content">
                          <ul className="cs-price-card-features">
                            <li>1 - 5 Users</li>
                            <li>5 GB Upload Size</li>
                            <li>Full Customer Support</li>
                            <li>Free Updates</li>
                            <li>Maximum Protection</li>
                          </ul>
                          <div>
                            <a href="#" className="btn btn-light btn-round btn-sm">
                              Get Started
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className="py-80">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="section-head text-center">
                  <h1 className="text-primary">Trusted by Most Popular Brands</h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <ul className="partners-horiz-list clearfix">
                  <li
                    className="partners-horiz-item"
                    data-aos="flip-up"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-duration="800"
                  >
                    <img
                      src="images/partners/p1.png"
                      className="img-fluid partner-img"
                      alt="partner"
                    />
                  </li>
                  <li
                    className="partners-horiz-item"
                    data-aos="flip-up"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-duration="1000"
                  >
                    <img
                      src="images/partners/p2.png"
                      className="img-fluid partner-img"
                      alt="partner"
                    />
                  </li>
                  <li
                    className="partners-horiz-item"
                    data-aos="flip-up"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-duration="1300"
                  >
                    <img
                      src="images/partners/p3.png"
                      className="img-fluid partner-img"
                      alt="partner"
                    />
                  </li>
                  <li
                    className="partners-horiz-item"
                    data-aos="flip-up"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-duration="1600"
                  >
                    <img
                      src="images/partners/p4.png"
                      className="img-fluid partner-img"
                      alt="partner"
                    />
                  </li>
                  <li
                    className="partners-horiz-item"
                    data-aos="flip-up"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-duration="1900"
                  >
                    <img
                      src="images/partners/p5.png"
                      className="img-fluid partner-img"
                      alt="partner"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="bg-gray py-80" id="webinars">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-head text-center">
                <h1 className="text-primary">Upcoming Webinars</h1>
                <p>
                  WiClose more deals than ever, automatice lead
                  captures,in-built phone,smart alerts with push notifcations.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div
                className="card cs-webnair-card"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <div className="card-body">
                  <h4 className="card-title text-primary">
                    Li Europan lingues es membres delsam
                  </h4>
                  <p className="card-text">
                    Li Europan lingues es membres del sam familie. Lor separat
                    existentie es un myth. Por scientie, musica, sport etc,
                    litot Europa.
                  </p>
                  <div className="media">
                    <img
                      className="mr-3"
                      src="images/profile1.png"
                      alt="image"
                      width="50"
                      height="50"
                    />
                    <div className="media-body">
                      <h5>
                        <small>By</small> Fuge Hamlinton
                      </h5>
                      Marketing Manager, TSW
                    </div>
                  </div>
                </div>
                <div className="card-actions">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="webnair-date">
                        <h5>Apr</h5>
                        <h2>18</h2>
                        <p>10:30AM IST</p>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="my-4 text-center">
                        <a href="#" className="btn btn-primary btn-round">
                          Im Interested <i className="fa fa-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="card cs-webnair-card"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="1500"
              >
                <div className="card-body">
                  <h4 className="card-title text-primary">
                    Li Europan lingues es membres delsam
                  </h4>
                  <p className="card-text">
                    Li Europan lingues es membres del sam familie. Lor separat
                    existentie es un myth. Por scientie, musica, sport etc,
                    litot Europa.
                  </p>
                  <div className="media">
                    <img
                      className="mr-3"
                      src="images/profile1.png"
                      alt="image"
                      width="50"
                      height="50"
                    />
                    <div className="media-body">
                      <h5>
                        <small>By</small> Fuge Hamlinton
                      </h5>
                      Marketing Manager, TSW
                    </div>
                  </div>
                </div>
                <div className="card-actions">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="webnair-date">
                        <h5>Apr</h5>
                        <h2>18</h2>
                        <p>10:30AM IST</p>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="my-4 text-center">
                        <a href="#" className="btn btn-primary btn-round">
                          Im Interested <i className="fa fa-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className="call-action-primary py-80">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="call-action-primary-content text-center">
                  <h1 className="text-white  mb-3 call-action-head">
                    Join our TM Spy for Free?
                  </h1>
                  <p className="text-white call-action-text">
                    Lor separat existentie es un myth. Por scientie, musica,
                    sport etc, litot Europa usa li sam vocabular. Li lingues
                    differe solmen in li grammatica
                  </p>

                  <form className="mt-5 ">
                    <div className="input-group call-action-primary-search">
                      <input
                        type="text"
                        className="form-control call-action-primary-input"
                        placeholder="Recipient's username"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                      <div className="input-group-append">
                        <button type="submit" className="call-action-primary-btn ">
                          Get Started
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className='dots'>
          <div className='dot one'></div>
          <div className='dot two'></div>
          <div className='dot three'></div>
          <div className='dot four'></div>
          <div className='dot five'></div>
          <div className='dot six'></div>
          <div className='dot seven'></div>
          <div className='dot eight'></div>
          <div className='dot nine'></div>
          <div className='dot ten'></div>
        </div>

        <Footer />

        <div
          className='modal cs-modal'
          id='signup-modal'
          tabIndex='-1'
          role='dialog'
          aria-hidden='true'>
          <div
            className='modal-dialog modal-dialog-centered modal-lg modal-full'
            role='document'>
            <div className='modal-content custom-modal-content'>
              <div className='  bg-gray  '>
                <div className='row'>
                  <div className='col-lg-6 pr-0 h-100 hide-sm'>
                    <div className=''>
                      <div className='sign-left-box'>
                        <h2 className='text-center'>CRM</h2>
                        <p className='text-center'>
                          With CRM, your team can now use AI-based lead scoring
                          email, activity capture and more.
                        </p>
                        <ul className='list-unstyled mt-5'>
                          <li className='media'>
                            <img
                              className='mr-3'
                              src='images/icn-1-white.svg'
                              alt=''
                            />
                            <div className='media-body'>
                              <h4 className='text-white'>Service Cloud</h4>
                              <p className='text-white'>
                                Sell smarter and faster with the world’s #1 CRM
                                solution.
                              </p>
                            </div>
                          </li>
                          <li className='media my-4'>
                            <img
                              className='mr-3'
                              src='images/icn-2-white.svg'
                              alt=''
                            />
                            <div className='media-body'>
                              <h4 className='text-white'>Sales Cloud</h4>
                              <p className='text-white'>
                                Support every customer.
                                <br /> Anytime. Anywhere.
                              </p>
                            </div>
                          </li>
                          <li className='media'>
                            <img
                              className='mr-3'
                              src='images/icn-3-white.svg'
                              alt=''
                            />
                            <div className='media-body'>
                              <h4 className='text-white'>Marketing Cloud</h4>
                              <p className='text-white'>
                                The future of marketing is 1-to-1 customer
                                journeys.{' '}
                              </p>
                            </div>
                          </li>
                        </ul>
                        <div
                          className='close-modal cs-fade-in-up'
                          data-dismiss='modal'
                          aria-label='Close'>
                          &nbsp; Back to Home
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 cpl-0 h-100 '>
                    <div className='sign-form'>
                      <div
                        className='show-sm modal-fixed-close'
                        data-dismiss='modal'
                        aria-label='Close'>
                        <i className='fa fa-times-circle'></i>
                      </div>
                      <h4 className='sign-form-head text-center'>
                        Signup and Start your Trail
                      </h4>
                      <form>
                        <div className='form-group  uiIcon'>
                          <input
                            type='text'
                            className='input form-control'
                            placeholder='Full Name'
                            required
                          />
                          <svg
                            className='svgIcon fill-smoke--d'
                            width='30'
                            height='30'
                            viewBox='0 -6 30 30'
                            enableBackground='new 0 -6 30 30'>
                            <path d='M22.9 16.2c.1-.1.1-.3.1-.4-.2-.8-.5-1.4-.9-1.9s-1-1-1.7-1.4c-.2-.1-.6-.3-1.3-.6-.6-.2-1.1-.4-1.4-.6-.3-.2-.6-.4-.7-.7-.1-.4.1-1 .5-1.7 1.5-2.3 1.8-4.3.8-5.9-.3-.6-.7-1.1-1.3-1.5-.6-.3-1.3-.5-2-.5s-1.4.2-2 .5c-.6.4-1.1.8-1.5 1.5-1 1.6-.7 3.6.8 5.9.5.7.7 1.3.5 1.7-.1.2-.2.4-.5.6-.2.2-.5.3-.7.4-.2.1-.5.2-.9.4-.6.3-1.1.4-1.3.6-.7.4-1.3.9-1.7 1.4-.2.4-.5 1-.7 1.8 0 .1 0 .3.1.4l.3.3c1.6.3 4.1.5 7.6.5 2.3 0 4.2-.1 5.8-.3.2 0 .4 0 .6-.1.2 0 .4-.1.5-.1l.3-.1h.30000000000000004c.2 0 .3-.1.4-.2z'></path>
                          </svg>
                        </div>
                        <div className='form-group  uiIcon'>
                          <input
                            type='text'
                            className='input form-control'
                            placeholder='Email Address'
                            required
                          />
                          <svg
                            className='svgIcon fill-smoke--d'
                            width='30'
                            height='30'
                            viewBox='0 -6 30 30'
                            enableBackground='new 0 -6 30 30'>
                            <path d='M15 11.9c-.9 0-1.7-.2-2.3-.7l-5.7-4.4v8.1c0 .1 0 .3.1.4.1.1.2.2.3.2 2.5.4 5 .5 7.5.5s5-.2 7.5-.5c.1 0 .3-.1.4-.2.2-.1.2-.3.2-.4v-8.1l-5.7 4.4c-.6.5-1.4.7-2.3.7zm-1.3-2.3c.3.3.8.4 1.3.4s1-.1 1.3-.4l6.1-4.7c.3-.3.5-.6.6-1v-.8c0-.1 0-.3-.1-.4-.1-.1-.2-.2-.4-.2-2.5-.3-5-.5-7.5-.5s-5 .2-7.5.5c-.1 0-.2.1-.3.2-.2.1-.2.3-.2.4v.9c.1.3.3.6.6 1l6.1 4.6z'></path>
                          </svg>
                        </div>
                        <div className='form-group  uiIcon'>
                          <input
                            type='text'
                            className='input form-control'
                            placeholder='Company Name'
                            required
                          />
                          <svg
                            className='svgIcon fill-smoke--d'
                            width='30'
                            height='30'
                            viewBox='0 0 30 30'>
                            <path d='M17,20v4h6q0-9-.46-13.55A15.6,15.6,0,0,0,22,7.29a1.1,1.1,0,0,0-1-.8A36.33,36.33,0,0,0,15,6a36.43,36.43,0,0,0-6,.48,1.15,1.15,0,0,0-1.07.84,15.29,15.29,0,0,0-.49,3.11Q7,15.15,7,24h6V20ZM19,9v3H16V9Zm0,5v3H16V14ZM14,9v3H11V9Zm0,5v3H11V14Z'></path>
                          </svg>
                        </div>
                        <div className='form-group  uiIcon'>
                          <input
                            type='text'
                            className='input form-control'
                            placeholder='Password'
                            required
                          />
                          <svg
                            className='svgIcon fill-smoke--d'
                            width='30'
                            height='30'
                            viewBox='0 -6 30 30'
                            enableBackground='new 0 -6 30 30'>
                            <path d='M21.6 8.3c-.2-.2-.5-.3-.9-.3h-.7v-2.6c0-1.3-.5-2.4-1.4-3.2-.9-.8-2.1-1.2-3.6-1.2s-2.7.4-3.6 1.2c-.9.8-1.4 1.9-1.4 3.2v2.6h-.7c-.3 0-.7.1-.9.4-.3.2-.4.5-.4.8v6.1c0 .3.1.5.3.7.2.2.4.3.7.4 1.9.4 3.9.6 6 .6 2 0 4-.2 5.9-.6.3-.1.5-.2.7-.4.2-.2.3-.4.3-.7v-6.1c.1-.3 0-.6-.3-.9zm-8.6-2.4c0-1.3.7-1.9 2-1.9s2 .6 2 1.9v2.1h-4v-2.1z'></path>
                          </svg>
                        </div>
                        <div className='form-group '>
                          <div className='input-group mb-3  uiIcon'>
                            <input
                              type='text'
                              className='input form-control'
                              placeholder='CRM Name'
                              aria-label="Recipient's username"
                              aria-describedby='basic-addon2'
                            />
                            <svg
                              className='svgIcon fill-smoke--d'
                              xmlns='http://www.w3.org/2000/svg'
                              width='30'
                              height='30'
                              viewBox='0 0 30 30'>
                              <path d='M7 15c0-4.4 3.6-8 8-8s8 3.6 8 8-3.6 8-8 8-8-3.6-8-8zm4.3 1.1h-2.4c.3 2.1 1.6 3.8 3.5 4.6-.6-1.2-1-3-1.1-4.6zm0-2.2c.1-1.6.5-3.3 1-4.5-1.8.9-3.1 2.4-3.5 4.5h2.5zm5.6 2.2h-3.9c.2 3.2 1.6 5.2 1.9 5.2.5 0 1.8-2 2-5.2zm-1.9-7.5c-.3 0-1.7 1.6-1.9 5.3h3.8c-.2-3.7-1.5-5.3-1.9-5.3zm2.7.8c.6 1.2.9 2.9 1 4.5h2.4c-.3-2.1-1.6-3.7-3.4-4.5zm3.4 6.7h-2.4c-.1 1.6-.5 3.4-1 4.6 1.8-.9 3.1-2.5 3.4-4.6z'></path>
                            </svg>
                            <div className='input-group-append'>
                              <span className='input-group-text' id='basic-addon2'>
                                .crm.com
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='form-group'>
                          <button
                            type='submit'
                            className='btn btn-primary btn-block'>
                            Signup for Free
                          </button>
                        </div>
                      </form>
                      <div className='sign-form-text'>
                        By signing up, you agree to our <a href='#'>T&C</a> and{' '}
                        <a href='#'>Privacy Policy</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a href='#0' className='cd-top js-cd-top'>
          <i className='fa fa-angle-up'></i>
        </a>
        <ContactModal
          show={showContactModal}
          handleClose={this.handleCloseContact}
        />
      </>
    )
  }
}

Home.propTypes = {}
export default Home
Home.contextType = AppContext
