import axios from "axios";
import LocalStorageService from "../../../LocalStorageService";

const localStorageService = LocalStorageService.getService();

export const getAllTrademark = async (withQuery = "") => {
  try {
    const { data } = await axios.get(
      `trademarks?companyId=${localStorageService.getCompanyId()}${withQuery}`
    );
    return Promise.resolve(data);
  } catch (err) {
    console.log(err, "error");

    return err;
  }
};
export const deleteTrademark = async (trademarkid, id) => {
  try {
    const { data } = await axios.delete(`trademarks/${trademarkid}`, {
      headers: {
        Authorization: LocalStorageService.getToken(),
      },
      data: {
        companyId: localStorageService.getCompanyId(),
      },
    });
    return data;
  } catch (err) {
    console.log(err, "error");

    return err;
  }
};
export const addTrademark = async (trademark, id) => {
  try {
    const { data } = await axios.post(`trademarks/`, {
      companyId: id,
      trademarks: trademark,
    });
    return Promise.resolve(data);
  } catch (err) {
    console.log(err, "error");

    return err;
  }
};
export const getSearchedTrademarkData = async (key, value) => {
  try {
    const { data } = await axios.get(
      `trademarks?companyId=${localStorageService.getCompanyId()}&searchKey=${key}&searchValue=${value}`
    );
    return Promise.resolve(data);
  } catch (err) {
    console.log(err, "error");

    return err;
  }
};
export const getFilterTrademarkData = async (type) => {
  try {
    const { data } = await axios.get(
      `trademarks?companyId=${localStorageService.getCompanyId()}&type=${type}`
    );
    return Promise.resolve(data);
  } catch (err) {
    console.log(err, "error");

    return err;
  }
};
export const setWatchedTradmark = async (trademarkid, ceaseDesist) => {
  try {
    const { data } = await axios.put(`/trademarks/${trademarkid}`, {
      ceaseDesist,
      companyId: localStorageService.getCompanyId(),
    });
    return Promise.resolve(data);
  } catch (err) {
    console.log(err, "error");

    return err;
  }
};
export const setKeywordsTrademark = async (trademarkid, keywords) => {
  try {
    const { data } = await axios.put(`/trademarks/${trademarkid}`, {
      keywords,
      companyId: localStorageService.getCompanyId(),
    });
    return Promise.resolve(data);
  } catch (err) {
    console.log(err, "error");

    return err;
  }
};
export const setClassTrademark = async (trademarkid, classes) => {
  try {
    const { data } = await axios.put(`/trademarks/${trademarkid}`, {
      compareClass: classes,
      companyId: localStorageService.getCompanyId(),
    });
    return Promise.resolve(data);
  } catch (err) {
    console.log(err, "error");

    return err;
  }
};
export const setSpyPublications = async (trademarkid, spy) => {
  try {
    const { data } = await axios.put(`/trademarks/${trademarkid}`, {
      spy,
      companyId: localStorageService.getCompanyId(),
    });
    return Promise.resolve(data);
  } catch (err) {
    console.log(err, "error");

    return err;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllTrademark,
  getSearchedTrademarkData,
  deleteTrademark,
  setWatchedTradmark,
  getFilterTrademarkData,
  addTrademark,
  setKeywordsTrademark,
  setClassTrademark,
  setSpyPublications,
};
