import React, { useEffect, useState, useContext, useMemo } from "react";
import { IconButton, Tooltip } from "@material-ui/core";

import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { withStyles } from "@material-ui/core/styles";
import ExcelIcon from "../../../assets/img/icon/excel.png";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfDocument } from "./PdfDocument";
import { AppContext } from "../../../context";
import { RowSelectContext } from "../../../context/RowSelectContext";

const defaultToolbarStyles = {
  iconButton: {},
};

const PdfControl = (props) => {
  const {
    classes,
    exportToCSV,
    fromPublishedMark,
    fromNewApplication,
    fromMyTrademark,
    handleOpenModal,
  } = props;
  const context = useContext(AppContext);
  const contextRowSelect = useContext(RowSelectContext);

  const {
    comparisionPdfData,
    newApplicationPdfData,
    journalPdfData,
    myPublishPdfData,
    myTrademarkPdfData,
    journalContext,
  } = context;
  const { rowSelectedDataTemp } = contextRowSelect;
  const [PdfData, setPdfData] = useState([]);
  const [logoMarkVisible, setLogoMarkVisible] = useState(false);
  const [publishVisible, setPublishVisible] = useState(false);
  const [newApplicationVisible, setNewApplicationVisible] = useState(false);
  const [trademarkVisible, setTrademarkVisible] = useState(false);
  const [comparisionVisible, setComparisionVisible] = useState(false);

  const handleClick = () => {
    if (rowSelectedDataTemp.length > 0 && comparisionPdfData.length > 0) {
      const rowSelectedDataObj = rowSelectedDataTemp.map((data) => ({
        applicationNo: data.applicationNo,
        class: data.class,
        mark: data.mark,
        score: data.score,
        conflictMark: data.conflictMark,
        conflictClass: data.conflictClass,
        conflictApplicationNo: data.conflictApplicationNo,
      }));
      exportToCSV(rowSelectedDataObj, "TMJ-Data");
    } else if (rowSelectedDataTemp.length > 0 && fromPublishedMark) {
      const rowSelectedDataObj = rowSelectedDataTemp.map((data) => ({
        applicationNo: data.applicationNo,
        class: data.class,
        mark: data.mark,
        page: data.page,
        type: data.type,
      }));
      exportToCSV(rowSelectedDataObj, "TMJ-Data");
    } else if (
      rowSelectedDataTemp.length > 0 &&
      myTrademarkPdfData.length > 0
    ) {
      exportToCSV(rowSelectedDataTemp, "TMJ-Data");
    } else if (rowSelectedDataTemp.length > 0 && journalPdfData.length > 0) {
      exportToCSV(rowSelectedDataTemp, "TMJ-Data");
    } else {
      if (journalPdfData.length > 0) {
        const logoMarksDataObj = journalPdfData.map((data) => ({
          applicationNo: data.applicationNo,
          page: data.page,
          class: data.class,
          applicationDate: data.applicationDate,
          mark: data.mark,
        }));
        exportToCSV(logoMarksDataObj, "TMJ-Data");
      }
      if (myPublishPdfData.length > 0) {
        exportToCSV(myPublishPdfData, "TMJ-Data");
      }
      if (myTrademarkPdfData.length > 0) {
        exportToCSV(myTrademarkPdfData, "TMJ-Data");
      }
      if (comparisionPdfData.length > 0) {
        const wordMarksDataObj = comparisionPdfData.map((data) => ({
          applicationNo: data.applicationNo,
          class: data.class,
          mark: data.mark,
          score: data.score,
          conflictMark: data.conflictMark,
          conflictClass: data.conflictClass,
          conflictApplicationNo: data.conflictApplicationNo,
        }));
        exportToCSV(wordMarksDataObj, "TMJ-Data");
      }
    }
  };

  useEffect(() => {
    if (rowSelectedDataTemp.length > 0) {
      setPdfData(rowSelectedDataTemp);
    } else {
      if (journalPdfData.length > 0) {
        setPdfData(journalPdfData);
        setLogoMarkVisible(true);
        setPublishVisible(false);
        setTrademarkVisible(false);
        setComparisionVisible(false);
      }
      if (newApplicationPdfData.length > 0) {
        setPdfData(newApplicationPdfData);
        setNewApplicationVisible(true);
        setPublishVisible(false);
        setLogoMarkVisible(false);
        setTrademarkVisible(false);
        setComparisionVisible(false);
      }
      if (myPublishPdfData.length > 0) {
        setPdfData(myPublishPdfData);
        setPublishVisible(true);
        setLogoMarkVisible(false);
        setTrademarkVisible(false);
        setComparisionVisible(false);
      }
      if (myTrademarkPdfData.length > 0) {
        setPdfData(myTrademarkPdfData);
        setTrademarkVisible(true);
        setPublishVisible(false);
        setLogoMarkVisible(false);
        setComparisionVisible(false);
      }
      if (comparisionPdfData.length > 0) {
        setPdfData(comparisionPdfData);
        setComparisionVisible(true);
        setPublishVisible(false);
        setLogoMarkVisible(false);
        setTrademarkVisible(false);
      }
    }
  }, [
    comparisionPdfData,
    newApplicationPdfData,
    contextRowSelect,
    rowSelectedDataTemp,
    journalPdfData,
    myPublishPdfData,
    myTrademarkPdfData,
    PdfData,
  ]);

  const memoizedPdfData = useMemo(() => PdfData, [PdfData]);

  return (
    <React.Fragment>
      <div>
        {fromMyTrademark && (
          <Tooltip title={"Add Trademark"}>
            <IconButton
              style={{ outline: "none" }}
              className={classes.iconButton}
              onClick={handleOpenModal}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title={"Export to PDF"}>
          <IconButton className={classes.iconButton}>
            <PDFDownloadLink
              document={
                <PdfDocument
                  pData={memoizedPdfData}
                  logoMarkVisible={logoMarkVisible}
                  publishVisible={publishVisible}
                  trademarkVisible={trademarkVisible}
                  newApplicationVisible={newApplicationVisible}
                  comparisionVisible={comparisionVisible}
                  journalContext={journalContext}
                />
              }
              fileName="TMJ Data.pdf"
              className="export_icon"
            >
              <PictureAsPdfIcon
                className={`${classes.deleteIcon} text-primary`}
              />
            </PDFDownloadLink>
          </IconButton>
        </Tooltip>
        <Tooltip
          title={"Export to Excel"}
          className={`excel_wrap ${fromNewApplication ? "d-none" : ""}`}
          id="excel_wrap"
        >
          <IconButton className={classes.iconButton} onClick={handleClick}>
            <img alt="" src={ExcelIcon} />
          </IconButton>
        </Tooltip>
      </div>
    </React.Fragment>
  );
};

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(
  PdfControl
);
