import axios from 'axios'
import LocalStorageService from '../../LocalStorageService'

const localStorageService = LocalStorageService.getService()

export const getAllJournalsDashboard = async (id) => {
  try {
    const { data } = await axios.get(
      `journals?companyId=${localStorageService.getCompanyId()}&pageType=dashboard`
    )
    return data
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
export const getAllComparisonsDashboard = async (journal, page) => {
  try {
    const { data } = await axios.get(
      `journals/${journal}/comparisons?companyId=${localStorageService.getCompanyId()}&infringementType=oppositionTracking`
    )
    return Promise.resolve(data)
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
export const getAllTrackNewApplications = async (journal) => {
  try {
    const { data } = await axios.get(
      `newapplications?companyId=${localStorageService.getCompanyId()}&type=track`
    )
    return data
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
export const getAllPublishNewApplications = async (journal) => {
  try {
    const { data } = await axios.get(
      `newapplications?companyId=${localStorageService.getCompanyId()}&type=publish`
    )
    return data
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllJournalsDashboard,
  getAllComparisonsDashboard,
  getAllTrackNewApplications,
  getAllPublishNewApplications,
}
