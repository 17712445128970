/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
// ES6
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";

import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Snackbar,
  TextField,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@material-ui/core/";
import EmailIcon from "@material-ui/icons/Email";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import Overlay from "react-bootstrap/Overlay";
import { Table, Dropdown, Modal } from "react-bootstrap/";
import Popover from "react-bootstrap/Popover";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Skeleton from "@material-ui/lab/Skeleton";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../context";
import { RowSelectContext } from "../../../context/RowSelectContext";
import {
  getAllWordMarksFromApi,
  getAllJournals,
  getSearchedWordMarkData,
  getConflickMarkDetails,
  getClientMarkDetails,
  updateComparisonFlag,
  getPublishDate,
} from "./WordMarkService";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import LocalStorageService from "../../../LocalStorageService";
import config from "../../../environment";
import Loader from "../../includes/Loader";

import MuiAlert from "@material-ui/lab/Alert";

import PdfControl from "../PDF/PdfControl";

const localStorageService = LocalStorageService.getService();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
let rowSelectedList = [];
let pageCountObj = 0;
let isNextClick = true;
const WordMarks = () => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const context = useContext(AppContext);
  const contextRowSelect = useContext(RowSelectContext);

  const { setComparisionPdfData, setJournalContext } = context;
  let history = useHistory();
  const [wordMarks, setWordMarks] = useState([]);
  const [journalData, setJournalData] = useState([]);
  const [journal, setJournal] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentRowData, setCurrentRowData] = useState([]);
  const [infrigementType, setInfrigementType] = useState("sameClass");
  const [rowSelectedData, setRowSelectedData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedValue, setSelectedValue] = useState("applicationNo");
  const [showConflict, setShowConflict] = useState(false);
  const [showClient, setShowClient] = useState(false);
  const [targetConflict, setTargetConflict] = useState(null);
  const [targetClient, setTargetClient] = useState(null);
  const ref = useRef(null);
  const [conflictMarkDetails, setConflictMarkDetails] = useState({});
  const [clientMarkDetails, setClientMarkDetails] = useState({});
  const [detailDataLoading, setDetailDataLoading] = useState(false);
  const [detailDataLoadingConflict, setDetailDataLoadingConflict] =
    useState(false);
  const [mailToContent, setMailToContent] = useState({});
  const [isEmailDataLoading, setIsEmailDataLoading] = useState(false);
  const [endOfData, setEndOfData] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isPdfOpen, setIsPdfOpen] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [showMailModal, setShowMailModal] = useState(false);
  const [pageId, setPageId] = useState([]);

  const handleCloseMailModal = () => setShowMailModal(false);
  const handleOpenMailModal = async (data) => {
    setIsEmailDataLoading(true);
    setShowMailModal(true);
    const resData = await getConflickMarkDetails(
      journal,
      data.conflictApplicationNo
    );
    const resPublishDate = await getPublishDate(journal);

    if (resData.goods) {
      setDetailDataLoadingConflict(false);
    }

    setMailToContent({
      ...data,
      ...resData,
      publishDate: resPublishDate.publicationDate,
      deadLine: resPublishDate.deadlineDate,
    });
    setIsEmailDataLoading(false);
  };

  // const copyTextRef = useRef()

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget)
  // }

  // const handleCloseMenu = () => {
  //   setAnchorEl(null)
  // }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleValueChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChange = (event) => {
    const { setRowSelectedDataContext } = contextRowSelect;
    setIsLoading(true);
    const selectedValue = event.target.value;
    setJournal(selectedValue);
    setJournalContext(selectedValue);
    setPage(1);
    rowSelectedList = [];
    setWordMarks([]);
    getAllWordMarkList(selectedValue, infrigementType);
    setPageId([]);
    localStorage.removeItem("pageId");
    localStorage.removeItem("totalPageId");
    pageCountObj = 0;
    isNextClick = true;
    setRowSelectedDataContext(rowSelectedList);
  };
  const handlePageNextChange = () => {
    isNextClick = true;

    if (endOfData) {
      return;
    }

    getAllWordMarkList(
      journal,
      infrigementType,
      `&page=${pageId[pageCountObj]}`
    );

    localStorage.setItem("pageId", pageId[pageCountObj]);
    localStorage.setItem("totalPageId", JSON.stringify(pageId));
    pageCountObj++;
  };
  const handlePagePrevChange = () => {
    isNextClick = false;
    pageCountObj--;

    if (pageCountObj <= 0) {
      getAllWordMarkList(journal, infrigementType);
      setPageId([]);
      localStorage.removeItem("pageId");
      localStorage.removeItem("totalPageId");
    } else {
      getAllWordMarkList(
        journal,
        infrigementType,
        `&page=${pageId[pageCountObj - 1]}`
      );
    }
    const tempPageId = [...pageId];
    tempPageId.splice(pageCountObj + 1, 1);
    setPageId(tempPageId);
    setEndOfData(false);
  };
  const backToPageOneHandler = () => {
    getAllWordMarkList(journal, infrigementType);
    setPageId([]);
    pageCountObj = 0;
  };
  const handleChangeInfrigementType = (event) => {
    setIsLoading(true);
    const selectedValue = event.target.value;

    setInfrigementType(selectedValue);
    setPageId([]);
    localStorage.removeItem("pageId");
    localStorage.removeItem("totalPageId");
    pageCountObj = 0;
    isNextClick = true;
    getAllWordMarkList(journal, selectedValue);
  };
  const getJournals = useCallback(async () => {
    const res = await getAllJournals(localStorageService.getCompanyId());

    if (res.length > 0) {
      setJournalData(res);
      setTimeout(() => {
        setJournal(res[0]);
        setJournalContext(res[0]);
        console.log(localStorage.getItem("pageId"), "localStorage");
        if (localStorage.getItem("pageId")) {
          getAllWordMarkList(
            res[0],
            infrigementType,
            `&page=${localStorage.getItem("pageId")}`
          );
        } else {
          getAllWordMarkList(res[0], infrigementType);
        }
      }, 200);
    } else {
    }
  }, [infrigementType, page]);

  useEffect(() => {
    const { setAuthVisible } = context;
    const { setRowSelectedDataContext } = contextRowSelect;
    if (localStorageService.getToken()) {
      setAuthVisible(false);
      setTimeout(() => {
        getJournals();
      }, 1000);
    } else {
      history.push("/login");
    }
    return () => {
      setWordMarks([]);
      setRowSelectedDataContext([]);
      rowSelectedList = [];
      setComparisionPdfData("comparisionPdfData", []);
      setPageId([]);
      localStorage.removeItem("pageId");
      localStorage.removeItem("totalPageId");
      pageCountObj = 0;
      isNextClick = true;
    };
  }, []);

  const getAllWordMarkList = useCallback(
    async (journalObj, infrigementTypeObj, pageObj) => {
      setIsLoading(true);
      setIsPdfOpen(true);
      const res = await getAllWordMarksFromApi(
        journalObj,
        infrigementTypeObj,
        pageObj
      );

      const wordMarkDataTemp = res.Items;

      if (wordMarkDataTemp && wordMarkDataTemp.length > 0) {
        const sorted = await wordMarkDataTemp.sort((a, b) =>
          a.score > b.score ? -1 : 1
        );

        setWordMarks(sorted);
        setComparisionPdfData("comparisionPdfData", sorted);

        setPageCount(res.Count);

        if (isNextClick) {
          setPageId((prevState) => [...prevState, res.PageId]);
          if (res.PageId) {
            setEndOfData(false);
          } else {
            setEndOfData(true);
          }
        }

        setTimeout(() => {
          setIsPdfOpen(false);
        }, 3000);
        setIsLoading(false);
      } else {
        setWordMarks([]);
        setIsLoading(false);
        setIsPdfOpen(false);
      }
    },
    []
  );

  const handleSearch = (e) => {
    const searchValue = e.target.value.trim();

    setSearchQuery(searchValue);
    if (searchValue && searchValue.length > 0) {
      // getSearchedData(searchValue);
    } else {
      setIsLoading(true);
      setCurrentRowData([]);
      getAllWordMarkList(journal, infrigementType);
      setPageId([]);
      localStorage.removeItem("pageId");
      localStorage.removeItem("totalPageId");
      pageCountObj = 0;
      isNextClick = true;
    }
  };

  const getSearchedData = async (valueObj) => {
    let isValid = false;
    const isNum = /^\d+$/.test(valueObj);
    if (!isNum && selectedValue === "applicationNo") {
      setOpen(true);
      setErrorMessage("Please enter number");
      isValid = false;
      return;
    } else if (isNum) {
      isValid = true;
    } else if (!isNum) {
      isValid = true;
    } else if (isNum && selectedValue === "mark") {
      setOpen(true);
      setErrorMessage("Please enter mark");
    }

    if (isValid) {
      setIsLoading(true);
      const resData = await getSearchedWordMarkData(
        journal,
        infrigementType,
        selectedValue,
        valueObj
      );
      const wordMarkDataTemp = resData.Items;

      if (wordMarkDataTemp && wordMarkDataTemp.length > 0) {
        const sorted = await wordMarkDataTemp.sort((a, b) =>
          a.score > b.score ? -1 : 1
        );

        setComparisionPdfData("comparisionPdfData", sorted);

        setWordMarks(sorted);
        setPageCount(resData.Count);
        setIsLoading(false);
        setPageId([]);
        localStorage.removeItem("pageId");
        localStorage.removeItem("totalPageId");
        pageCountObj = 0;
        isNextClick = true;
      } else {
        setWordMarks([]);
        setIsLoading(false);
      }
    }
  };

  const searchHandler = () => {
    getSearchedData(searchQuery);
  };

  const rowSelectHandler = (appNo, e) => {
    setIsPdfOpen(true);
    const { setRowSelectedDataContext } = contextRowSelect;

    if (e.target.checked) {
      const selectedRow = wordMarks.find((p) => p.id === appNo.id);
      rowSelectedList.push({ ...selectedRow });

      setRowSelectedData(rowSelectedList);
      setRowSelectedDataContext(rowSelectedList);
    } else {
      const rowIndex = rowSelectedList.findIndex((p) => p.id === appNo.id);
      rowSelectedList.splice(rowIndex, 1);
      setRowSelectedDataContext(rowSelectedList);
    }
    setTimeout(() => {
      setIsPdfOpen(false);
    }, 5000);
  };

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleClickConflictMark = async (event, data) => {
    setDetailDataLoadingConflict(true);
    setShowConflict(!showConflict);
    setTargetConflict(event.target);
    const resData = await getConflickMarkDetails(journal, data);

    if (resData.goods) {
      setDetailDataLoadingConflict(false);
    }
    setConflictMarkDetails(resData);
  };
  const handleClickClientMark = async (event, data) => {
    setDetailDataLoading(true);

    setShowClient(!showClient);
    setTargetClient(event.target);

    const resData = await getClientMarkDetails(data);
    if (resData.goods) {
      setDetailDataLoading(false);
    }

    setClientMarkDetails(resData);
  };

  const handleClickClientMarkClose = () => {
    setShowClient(false);
  };
  const handleClickConflictMarkClose = () => {
    setShowConflict(false);
  };

  const updateComparisonFlagHandler = async (id, type) => {
    let tempData = [...wordMarks];
    tempData.forEach((element) => {
      if (element.id === id) {
        element.oppose = type;
      }
    });
    setWordMarks(tempData);
    await updateComparisonFlag(journal, id, type);
    // getAllWordMarkList(journal, infrigementType, page)
  };

  function CopyHTMLToClipboard(element) {
    const doc = document;
    const text = doc.getElementById(element);
    let range;
    let selection;

    if (doc.body.createTextRange) {
      range = doc.body.createTextRange();
      range.moveToElement(text);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();

      range = doc.createRange();
      range.selectNodeContents(text);

      selection.removeAllRanges();
      selection.addRange(range);
    }

    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    const copyBtn = document.getElementById("clickMe");
    if (copyBtn) {
      copyBtn.value = "Copied to clipboard!";
    }
  }

  const loadingComponent = (
    <div
      style={{
        position: "fixed",
        zIndex: 110,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(255,255,255,0.8)",
      }}
    >
      <Loader />
    </div>
  );

  // const memoizedWordmarkData = useMemo(() => getAllWordMarkList(), [])
  // const memoizedCurrentRowData = useMemo(() => currentRowData, [currentRowData])

  const totalPageIdCount = JSON.parse(localStorage.getItem("totalPageId"));

  console.log(pageId, "pageLoadCount");

  return (
    <>
      <div className="top_head_table">
        <div className="select_wrap">
          <FormControl variant="filled" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">
              Select Journal
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={journal}
              onChange={handleChange}
              label="Journal"
            >
              {journalData.map((j, i) => (
                <MenuItem value={j} key={i}>
                  {j}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">
              Select Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              onChange={handleChangeInfrigementType}
              value={infrigementType}
              label="Infringement"
            >
              <MenuItem value="sameClass">Same Class</MenuItem>
              <MenuItem value="identical">Different Class</MenuItem>
              <MenuItem value="oppositionTracking">Opposition Tracking</MenuItem>
              <MenuItem value="oppositionFiled">Opposition Filed</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="search_wrap">
          <TextField
            id="filled-basic"
            label="Search"
            variant="filled"
            className="search_box"
            onChange={handleSearch}
            value={searchQuery}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={searchHandler}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControl variant="filled" className="ml-1 select_column">
            <InputLabel id="demo-simple-select-label">Select column</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={selectedValue}
              onChange={handleValueChange}
            >
              <MenuItem value="applicationNo">Application No</MenuItem>
              <MenuItem value="mark">Mark</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="pagination_wrap">
          {/* <Pagination
            count={pageCount}
            page={page}
            color='primary'
            onChange={handlePageChange}
          /> */}
          <ul>
            {totalPageIdCount?.length > 0 && (
              <>
                {" "}
                <li>
                  <IconButton
                    aria-label="delete"
                    onClick={backToPageOneHandler}
                  >
                    <FirstPageIcon fontSize="large" />
                  </IconButton>
                </li>
                <li className="d-block">
                  <IconButton
                    aria-label="delete"
                    onClick={handlePagePrevChange}
                  >
                    <NavigateBeforeIcon fontSize="large" />
                  </IconButton>
                </li>
              </>
            )}

            <li className={endOfData ? "no_next" : ""}>
              {" "}
              <IconButton aria-label="delete" onClick={handlePageNextChange}>
                <NavigateNextIcon fontSize="large" />
              </IconButton>
            </li>
          </ul>
        </div>
      </div>
      {isLoading && loadingComponent}
      <div className="table_body_wrap comparision_wrap" ref={ref}>
        <div className="title_head">
          <h5>Comparisons</h5>
          {/* {isPdfOpen ? (
              'Loading...'
            ) : (
              <PdfControl
                exportToCSV={exportToCSV}
                searchedData={currentRowData}
                rowSelectedData={rowSelectedData}
              />
            )} */}
          <PdfControl
            exportToCSV={exportToCSV}
            searchedData={currentRowData}
            rowSelectedData={rowSelectedData}
          />
        </div>

        <Table bordered size="sm">
          <thead>
            <tr className="head_th_title">
              <th colSpan="4">
                <h5>Your Trade Marks</h5>{" "}
              </th>
              <th></th>
              <th colSpan="4">
                <h5>Conflicting Trade Marks</h5>
              </th>
            </tr>
            <tr>
              <td></td>
              <td>Application No</td>
              <td>Class</td>
              <td>Representation of Trade Mark</td>
              <td>Score %</td>
              <td>Representation of Trade Mark</td>
              <td>Class</td>
              <td>Application No</td>
            </tr>
          </thead>
          <tbody>
            {wordMarks.length > 0 ? (
              <>
                {wordMarks.map((word, i) => (
                  <tr key={i}>
                    <td style={{ width: "80px" }}>
                      <Checkbox
                        color="primary"
                        checked={rowSelectedList.some((p) =>
                          p.id === word.id ? 1 : 0
                        )}
                        onClick={(e) => rowSelectHandler(word, e)}
                        inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                      />
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <i
                            title={`${
                              word.oppose === "RED"
                                ? "Opposition Tracking"
                                : word.oppose === "GREEN"
                                ? "Opposition Filed"
                                : word.oppose === "NONE" && "none"
                            }`}
                            className={`fas fa-flag ${
                              word.oppose === "RED"
                                ? "red"
                                : word.oppose === "GREEN"
                                ? "green"
                                : word.oppose === "NONE" && "none"
                            }`}
                          ></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="javascript:void(0)"
                            title="Opposition Tracking"
                            onClick={() =>
                              updateComparisonFlagHandler(word.id, "RED")
                            }
                          >
                            <p>Opposition Tracking</p>
                            <i class="fas fa-flag red"></i>
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="javascript:void(0)"
                            title="Opposition Filed"
                            onClick={() =>
                              updateComparisonFlagHandler(word.id, "GREEN")
                            }
                          >
                            <p>Opposition Filed</p>
                            <i class="fas fa-flag green"></i>
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="javascript:void(0)"
                            onClick={() =>
                              updateComparisonFlagHandler(word.id, "NONE")
                            }
                          >
                            <p>None</p>

                            <i class="fas fa-flag "></i>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td
                      className="client_mark applicationTd"
                      style={{ width: "135px" }}
                    >
                      <a
                        onClick={(e) =>
                          handleClickClientMark(e, word.applicationNo)
                        }
                        rel="noopener noreferrer"
                        href="javascript:void(0)"
                      >
                        {word.applicationNo}
                      </a>
                    </td>
                    <td className="client_mark" style={{ width: "60px" }}>
                      {word.class}
                    </td>

                    <td className="client_mark" style={{ width: "435px" }}>
                      {" "}
                      <strong>{word.mark}</strong>{" "}
                      {word.cLogo && (
                        <img className="logo_mark" alt="" src={word.cLogo} />
                      )}
                    </td>
                    <td style={{ width: "71px" }}>{word.score}</td>
                    <td className="conflict_mark" style={{ width: "435px" }}>
                      <strong>{word.conflictMark}</strong>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {word.url && (
                        <img className="logo_mark" alt="" src={word.url} />
                      )}
                    </td>
                    <td className="conflict_mark" style={{ width: "60px" }}>
                      {word.conflictClass}
                    </td>
                    <td className="conflict_mark" style={{ width: "135px" }}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${config.PDF_URL}/${journal}/pages/${word.conflictApplicationNo}.pdf`}
                      >
                        <PictureAsPdfIcon />
                      </a>
                      <a
                        onClick={() => handleOpenMailModal(word)}
                        href="javascript:void(0)"
                        className="ml-2"
                      >
                        <EmailIcon className="text-danger" />
                      </a>
                      <br />

                      <a
                        onClick={(e) =>
                          handleClickConflictMark(e, word.conflictApplicationNo)
                        }
                        rel="noopener noreferrer"
                        href="javascript:void(0)"
                      >
                        {word.conflictApplicationNo}
                      </a>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="8">Sorry no record found!</td>
              </tr>
            )}
          </tbody>
        </Table>

        <Overlay
          show={showClient}
          target={targetClient}
          placement="right"
          container={ref.current}
          containerPadding={20}
        >
          <Popover id="popover-contained">
            <Popover.Title as="h3">
              Details
              <div
                className="close_popover"
                onClick={handleClickClientMarkClose}
              >
                <CloseIcon />
              </div>
            </Popover.Title>
            <Popover.Content>
              {detailDataLoading ? (
                <>
                  <Skeleton /> <Skeleton />{" "}
                </>
              ) : (
                <>
                  <strong>Application Date: </strong>{" "}
                  {clientMarkDetails.applicationDate} <br />
                  <br />
                  <strong>Used Since: </strong>{" "}
                  {clientMarkDetails.usedSinceDate} <br />
                  <br />
                  <strong>Goods/Services:</strong> {clientMarkDetails.goods}
                </>
              )}
            </Popover.Content>
          </Popover>
        </Overlay>

        <Overlay
          show={showConflict}
          target={targetConflict}
          placement="left"
          container={ref.current}
          containerPadding={20}
        >
          <Popover id="popover-contained">
            <Popover.Title as="h3">
              Details
              <div
                className="close_popover"
                onClick={handleClickConflictMarkClose}
              >
                <CloseIcon />
              </div>
            </Popover.Title>
            <Popover.Content>
              {detailDataLoadingConflict ? (
                <>
                  <Skeleton /> <Skeleton />{" "}
                </>
              ) : (
                <>
                  <strong>Application Date: </strong>{" "}
                  {conflictMarkDetails.applicationDate} <br />
                  <br />
                  <strong>Used Since: </strong>{" "}
                  {conflictMarkDetails.usedSinceDate} <br />
                  <br />
                  <strong>Goods/Services:</strong> {conflictMarkDetails.goods}
                </>
              )}
            </Popover.Content>
          </Popover>
        </Overlay>
      </div>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Modal
        show={showMailModal}
        onHide={handleCloseMailModal}
        size="lg"
        className="mail_to_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <input
              id="clickMe"
              className="btn btn-primary"
              onClick={() => CopyHTMLToClipboard("toCopyDiv")}
              type="button"
              value="Copy"
            ></input>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="copy_subject">
            <strong>Subject</strong> - Proposed opposition against application
            for registration of trade mark{" "}
            <strong>{mailToContent.conflictMark}</strong> under no.{" "}
            <strong>{mailToContent.conflictApplicationNo}</strong> in class{" "}
            <strong>{mailToContent.conflictClass}</strong>.
          </div>
          <div className="copy_body mt-4" id="toCopyDiv">
            <p>Dear Sir/Madam,</p>
            <p>
              While conducting a routine search in the Trade Marks Journal, we
              have come across the publication of the trade mark{" "}
              <strong>{mailToContent.conflictMark}</strong> detailed herein
              below which is similar to your trade mark. You are requested to
              kindly go through the relevant page of the Journal publication
              attached herewith:
            </p>
            <Table bordered className="mt-5 mb-5">
              <thead>
                <tr>
                  <th>Trade Mark</th>
                  <th>Application No.</th>
                  <th>Class</th>
                  <th>User since</th>
                  <th>Date of publication</th>
                  <th>Description of goods /services</th>
                  <th>Deadline</th>
                </tr>
              </thead>
              <tbody>
                {isEmailDataLoading && (
                  <tr>
                    <td className="text-center" colSpan={7}>
                      <Loader />
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    {mailToContent.url ? (
                      <img
                        height="120"
                        width="140"
                        src={mailToContent.url}
                        alt=""
                      />
                    ) : (
                      mailToContent.conflictMark
                    )}
                  </td>
                  <td>{mailToContent.conflictApplicationNo}</td>
                  <td>{mailToContent.conflictClass}</td>
                  <td>{mailToContent.usedSinceDate}</td>
                  <td>{mailToContent.publishDate}</td>
                  <td>{mailToContent.goods}</td>
                  <td>{mailToContent.deadLine}</td>
                </tr>
              </tbody>
            </Table>
            <p>
              You are requested to kindly let us know if you wish to oppose the
              said trade mark. Kindly make note of the deadline and let us have
              your instructions well before the deadline. In case you do not
              wish to oppose the said mark, then kindly let us know so as to
              enable us to remove the same from our list.{" "}
            </p>
            <p>Kindly acknowledge the receipt of this E-mail.</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WordMarks;
