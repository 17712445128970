import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap/";
import { TextField } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import { userSignup } from "./AuthService";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ContactModal = ({ show, handleClose }) => {
  const [open, setOpen] = React.useState(false);
  const [fields, setFields] = useState({ name: "", email: "", message: "" });

  const [isValidate, setValidate] = useState(false);
  const [errorMessageName, setErrorMessageName] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const [isRequiredName, setIsRequiredName] = useState(false);
  const [isRequiredMessage, setIsRequiredMessage] = useState(false);

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setFields({
      ...fields,
      [e.target.name]: value,
    });
    if (e.target.name === "email") {
      if (validateEmail(value)) {
        setFields({
          ...fields,
          [e.target.name]: value,
        });
        setValidate(false);
        setDisabled(false);
      } else {
        setValidate(true);
        // setEmail(e.target.value);
        setDisabled(true);
        setErrorMessageEmail("Please enter a valid email");
      }
    }
    setIsRequiredName(false);
    setIsRequiredMessage(false);
  };

  const onSubmitHandler = async () => {
    const userObj = {
      name: fields.name,
      email: fields.email,
      message: fields.message,
    };

    if (fields.name === "") {
      setIsRequiredName(true);
      setErrorMessageName("This field is required");
    } else if (fields.message === "") {
      setIsRequiredMessage(true);
      setErrorMessage("This field is required");
    } else {
      const res = await userSignup(userObj);

      if (res) {
        setIsRequiredName(false);
        setIsRequiredMessage(false);
        setOpen(true);

        setFields({ name: "", email: "", message: "" });
        setTimeout(() => {
          setOpen(false);
        }, 3000);
      }
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <TextField
              id="filled-basic"
              label="Name"
              fullWidth
              variant="filled"
              value={fields.name}
              name="name"
              onChange={handleChange}
            />
            {isRequiredName && (
              <label className="error">{errorMessageName}</label>
            )}
          </div>
          <div className="form-group">
            <TextField
              id="filled-basic"
              label="Email"
              fullWidth
              variant="filled"
              value={fields.email}
              name="email"
              onChange={handleChange}
            />
            {isValidate && <label className="error">{errorMessageEmail}</label>}
          </div>
          <div className="form-group">
            <TextField
              id="filled-multiline-static"
              label="Message"
              multiline
              rows={4}
              fullWidth
              variant="filled"
              value={fields.message}
              name="message"
              onChange={handleChange}
            />
            {isRequiredMessage && (
              <label className="error">{errorMessage}</label>
            )}
          </div>
          {open && (
            <Alert severity="success">
              Thank you for contacting us. We will get back to you soon.
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={isDisabled}
            onClick={onSubmitHandler}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ContactModal;
