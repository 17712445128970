import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap/";
import { TextField } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import { forgotPassword } from "./AuthService";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ForgotPassword = ({ show, handleClose }) => {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState("");
  const [isValidate, setValidate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setDisabled] = useState(true);

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChange = (e) => {
    if (validateEmail(e.target.value)) {
      setEmail(e.target.value);
      setValidate(false);
      setDisabled(false);
    } else {
      setValidate(true);
      setEmail(e.target.value);
      setDisabled(true);
      setErrorMessage("Please enter a valid email");
    }
  };

  const forgotPasswordHandler = async () => {
    const emailObj = {
      email,
    };

    const res = await forgotPassword(emailObj);

    if (res) {
      setValidate(false);
      setDisabled(true);
      setOpen(true);

      setEmail("");

      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <TextField
              id="filled-basic"
              label="Email"
              fullWidth
              variant="filled"
              value={email}
              onChange={handleChange}
            />
            {isValidate && <label className="error">{errorMessage}</label>}
          </div>
          {open && (
            <Alert onClose={handleClose} severity="success">
              Your request has been submitted successfully. You will receive the
              password shortly
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={forgotPasswordHandler}
            disabled={isDisabled}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ForgotPassword;
