import axios from 'axios'
import LocalStorageService from '../../LocalStorageService'

const localStorageService = LocalStorageService.getService()

export const getExclusionlist = async () => {
  try {
    const { data } = await axios.get(
      `exclusionlist?companyId=${localStorageService.getCompanyId()}`
    )
    return data
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
export const postExclusionlist = async (list) => {
  try {
    const { data } = await axios.post(`exclusionlist`, {
      companyId: localStorageService.getCompanyId(),
      exclusionList: list,
    })
    return data
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
export const deleteExclusionlist = async (list) => {
  console.log(list, 'asdfasdfasd')
  try {
    const { data } = await axios.delete(`exclusionlist`, {
      headers: {
        Authorization: LocalStorageService.getToken(),
      },
      data: {
        companyId: localStorageService.getCompanyId(),
        exclusionList: list,
      },
    })
    return data
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}

export const getNewapplications = async (list) => {
  try {
    const { data } = await axios.get(
      `newapplications/applicant?companyId=${localStorageService.getCompanyId()}`
    )
    return data
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
export const postNewapplications = async (classes, applicantName) => {
  try {
    const { data } = await axios.post(`newapplications/applicant`, {
      companyId: localStorageService.getCompanyId(),
      applicantName,
      classes,
    })
    return data
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
export const deleteNewApplicant = async (id) => {
  try {
    const { data } = await axios.delete(`newapplications/applicant/${id}`, {
      headers: {
        Authorization: LocalStorageService.getToken(),
      },
      data: {
        companyId: localStorageService.getCompanyId(),
      },
    })
    return data
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
export const updateNewapplications = async (classes, applicantName, id) => {
  try {
    const { data } = await axios.put(`newapplications/applicant/${id}`, {
      companyId: localStorageService.getCompanyId(),
      applicantName,
      classes,
    })
    return data
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getExclusionlist,
  postExclusionlist,
  deleteExclusionlist,
  getNewapplications,
  postNewapplications,
  deleteNewApplicant,
  updateNewapplications,
}
