import React, { useEffect, useState } from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'

import Logo from '../../../assets/img/ipbloc.jpg'

const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 40
const COLN_WIDTH = (100 - COL1_WIDTH) / 1
// Create styles
const styles = StyleSheet.create({
  body: {
    padding: 30,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  topTable: {
    display: 'table',
    width: 'auto',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol1Header: {
    width: COL1_WIDTH + '%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'center',
  },
  tableColHeader: {
    width: COLN_WIDTH + '%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'center',
  },
  tableColHeaderClass: {
    width: 20 + '%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'center',
  },
  tableColHeaderApplication: {
    width: 50 + '%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'center',
  },
  tableColHeaderApplicationWord: {
    width: 85 + '%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'center',
  },
  tableCol1: {
    width: COL1_WIDTH + '%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'center',
  },
  tableCol: {
    width: COLN_WIDTH + '%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 2,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'center',
  },
  tableColApplication: {
    width: 50 + '%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'center',
  },
  tableColApplicationWord: {
    width: 85 + '%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 2,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'center',
  },
  tableColClass: {
    width: 20 + '%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'center',
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  tableCellMark: {
    margin: 5,
    fontSize: 10,
    fontWeight: 600,
  },
  image: {
    height: 200,
    width: 200,
  },
  logoWrap: {
    marginBottom: 10,
    display: 'flex',
  },

  journalText: {
    width: '50%',
    textAlign: 'center',
    fontSize: 14,
    paddingBottom: 10,
    paddingTop: 10,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  logoImg: {
    height: 50,
    width: 50,
  },
  tRow: {
    flexDirection: 'row',
    alignItems: 'center',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
  },
  topRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tHead: {
    width: '50%',
    textAlign: 'center',
    fontSize: 14,
    paddingBottom: 10,
    paddingTop: 10,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  topHead: {
    width: '50%',
    textAlign: 'center',
    fontSize: 14,
    paddingBottom: 10,
    paddingTop: 10,
  },
  logoText: {
    width: '50%',
    textAlign: 'center',
    fontSize: 14,
    paddingBottom: 10,
    paddingTop: 10,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
})

// Create Document Component
export const PdfDocument = (props) => {
  const [pdfData, setPdfData] = useState([])
  const {
    pData,
    logoMarkVisible,
    publishVisible,
    trademarkVisible,
    newApplicationVisible,
    comparisionVisible,
    journalContext,
  } = props
  useEffect(() => {
    setPdfData(pData)
  }, [pData, logoMarkVisible, publishVisible])

  return (
    <Document>
      <Page style={styles.body} size='A4'>
        <View style={styles.topTable}>
          <View style={styles.topRow}>
            <Text style={styles.topHead}>
              {' '}
              Powered by <Image style={styles.logoImg} source={Logo} />
            </Text>
            <Text style={styles.topHead}>
              {' '}
              {(comparisionVisible || logoMarkVisible) && journalContext}
            </Text>
          </View>
        </View>

        {trademarkVisible && pdfData.length > 0 ? (
          <>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderApplicationWord}>
                  <Text style={styles.tableCellHeader}>Application No.</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Name</Text>
                </View>

                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Class</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Applicant Name</Text>
                </View>
              </View>
              {pData.length > 0
                ? pData.map((d, index) => {
                    return (
                      <View style={styles.tableRow} key={index}>
                        <View style={styles.tableColApplicationWord}>
                          <Text style={styles.tableCell}>
                            {d.applicationNo}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{d.name}</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{d.class}</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCellMark}>
                            {d.applicantName}
                          </Text>
                          {d.url && <Image source={d.url} />}
                        </View>
                      </View>
                    )
                  })
                : null}
            </View>
          </>
        ) : logoMarkVisible && pdfData.length > 0 ? (
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1Header}>
                <Text style={styles.tableCellHeader}>Logo</Text>
              </View>
              <View style={styles.tableColHeaderApplication}>
                <Text style={styles.tableCellHeader}>Application No.</Text>
              </View>
              <View style={styles.tableColHeaderClass}>
                <Text style={styles.tableCellHeader}>Class</Text>
              </View>
              <View style={styles.tableColHeaderApplication}>
                <Text style={styles.tableCellHeader}>Application Date</Text>
              </View>
              <View style={styles.tableColHeaderClass}>
                <Text style={styles.tableCellHeader}>Page</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Mark</Text>
              </View>
            </View>
            {pData.map((d, index) => {
              return (
                <View style={styles.tableRow} key={index}>
                  <View style={styles.tableCol1}>
                    {d.url && <Image source={d.url} />}
                  </View>
                  <View style={styles.tableColApplication}>
                    <Text style={styles.tableCell}>{d.applicationNo}</Text>
                  </View>
                  <View style={styles.tableColClass}>
                    <Text style={styles.tableCell}>{d.class}</Text>
                  </View>
                  <View style={styles.tableColApplication}>
                    <Text style={styles.tableCell}>{d.applicationDate}</Text>
                  </View>
                  <View style={styles.tableColClass}>
                    <Text style={styles.tableCell}>{d.page}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{d.mark}</Text>
                  </View>
                </View>
              )
            })}
          </View>
        ) : publishVisible && pdfData.length > 0 ? (
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeaderApplicationWord}>
                <Text style={styles.tableCellHeader}>Application No.</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Class</Text>
              </View>

              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Page</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Mark</Text>
              </View>
            </View>
            {pData.length > 0
              ? pData.map((d, index) => {
                  return (
                    <View style={styles.tableRow} key={index}>
                      <View style={styles.tableColApplicationWord}>
                        <Text style={styles.tableCell}>{d.applicationNo}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{d.class}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{d.page}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellMark}>{d.mark}</Text>
                        {d.url && <Image source={d.url} />}
                      </View>
                    </View>
                  )
                })
              : null}
          </View>
        ) : newApplicationVisible && pdfData.length > 0 ? (
          <>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderApplicationWord}>
                  <Text style={styles.tableCellHeader}>Application No.</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Mark</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Class</Text>
                </View>

                <View style={styles.tableColHeaderApplicationWord}>
                  <Text style={styles.tableCellHeader}>Application Name.</Text>
                </View>
                <View style={styles.tableColHeaderApplicationWord}>
                  <Text style={styles.tableCellHeader}>Application Date.</Text>
                </View>
              </View>
              {pData.length > 0
                ? pData.map((d, index) => {
                    return (
                      <View style={styles.tableRow} key={index}>
                        <View style={styles.tableColApplicationWord}>
                          <Text style={styles.tableCell}>
                            {d.applicationNo}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{d.mark}</Text>
                          {d.url && <Image src={d.url} />}
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{d.class}</Text>
                        </View>

                        <View style={styles.tableColApplicationWord}>
                          <Text style={styles.tableCell}>
                            {d.applicantName}
                          </Text>
                        </View>
                        <View style={styles.tableColApplicationWord}>
                          <Text style={styles.tableCell}>
                            {d.applicationDate}
                          </Text>
                        </View>
                      </View>
                    )
                  })
                : null}
            </View>
          </>
        ) : (
          comparisionVisible &&
          pdfData.length > 0 && (
            <View style={styles.table}>
              <View style={styles.tRow}>
                <Text style={styles.tHead}>Client Mark</Text>
                <Text style={styles.tHead}>Conflict Mark</Text>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderApplicationWord}>
                  <Text style={styles.tableCellHeader}>Application No.</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Class</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Mark</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Score %</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Mark</Text>
                </View>
                {/* <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Logo</Text>
          </View> */}
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Class</Text>
                </View>
                <View style={styles.tableColHeaderApplicationWord}>
                  <Text style={styles.tableCellHeader}>Application No.</Text>
                </View>
              </View>
              {pData.length > 0
                ? pData.map((d, index) => {
                    return (
                      <View style={styles.tableRow} key={index}>
                        <View style={styles.tableColApplicationWord}>
                          <Text style={styles.tableCell}>
                            {d.applicationNo}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{d.class}</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCellMark}>{d.mark}</Text>

                          {d.cLogo && <Image src={d.cLogo} />}
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{d.score}</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{d.conflictMark}</Text>
                          {d.url && <Image src={d.url} />}
                        </View>

                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {d.conflictClass}
                          </Text>
                        </View>
                        <View style={styles.tableColApplicationWord}>
                          <Text style={styles.tableCell}>
                            {d.conflictApplicationNo}
                          </Text>
                        </View>
                      </View>
                    )
                  })
                : null}
            </View>
          )
        )}
      </Page>
    </Document>
  )
}
