import React from "react";

const Loader = () => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          zIndex: 110,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(255,255,255,0.8)",
        }}
      >
        <div className="loadingio-spinner-dual-ball-xv37mtfudqd">
          <div className="ldio-hfjav9dlpac">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
