import axios from 'axios'
import LocalStorageService from '../../../LocalStorageService'

const localStorageService = LocalStorageService.getService()

export const getAllNewApplications = async (journal) => {
  try {
    const { data } = await axios.get(
      `newapplications?companyId=${localStorageService.getCompanyId()}&type=new`
    )
    return data
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
export const updateApplication = async (id, marker) => {
  try {
    const { data } = await axios.put(`newapplications/${id}`, {
      companyId: localStorageService.getCompanyId(),
      marker,
    })
    return data
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
export const getSearchedWordMarkData = async (journal, key, value) => {
  try {
    const { data } = await axios.get(
      `journals/${journal}/published?companyId=${localStorageService.getCompanyId()}&searchKey=${key}&searchValue=${value}`
    )
    return Promise.resolve(data)
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
export const filteerApplicationByMarker = async (type) => {
  try {
    const { data } = await axios.get(
      `newapplications?companyId=${localStorageService.getCompanyId()}&type=${type}`
    )
    return data
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllNewApplications,
  getSearchedWordMarkData,
  updateApplication,
  filteerApplicationByMarker,
}
