import axios from 'axios'
import LocalStorageService from '../../../LocalStorageService'

const localStorageService = LocalStorageService.getService()

export const getAllJournals = async (id) => {
  try {
    const { data } = await axios.get(
      `journals?companyId=${localStorageService.getCompanyId()}`
    )
    return data
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
export const getAllWordMarksFromApi = async (journal, type, withQuery = '') => {
  try {
    const { data } = await axios.get(
      `journals/${journal}/comparisons?companyId=${localStorageService.getCompanyId()}&infringementType=${type}${withQuery}`
    )
    return Promise.resolve(data)
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
export const getSearchedWordMarkData = async (journal, type, key, value) => {
  try {
    const { data } = await axios.get(
      `journals/${journal}/comparisons?companyId=${localStorageService.getCompanyId()}&infringementType=${type}&searchKey=${key}&searchValue=${value}`
    )
    return Promise.resolve(data)
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
export const updateComparisonFlag = async (journal, id, type) => {
  try {
    const { data } = await axios.put(`journals/${journal}/comparisons/${id}`, {
      companyId: localStorageService.getCompanyId(),
      oppose: type,
    })
    return Promise.resolve(data)
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
export const getConflickMarkDetails = async (journal, applicationNo) => {
  try {
    const { data } = await axios.get(
      `journals/${journal}/trademarks/${applicationNo}`
    )
    return Promise.resolve(data)
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
export const getClientMarkDetails = async (applicationNo) => {
  try {
    const { data } = await axios.get(
      `trademarks/${applicationNo}?companyId=${localStorageService.getCompanyId()}`
    )
    return Promise.resolve(data)
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
export const getPublishDate = async (journalid) => {
  try {
    const { data } = await axios.get(`/journals/${journalid}`)
    return Promise.resolve(data)
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllWordMarksFromApi,
  getAllJournals,
  getSearchedWordMarkData,
  getConflickMarkDetails,
  getClientMarkDetails,
  updateComparisonFlag,
  getPublishDate,
}
