/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable no-unused-vars */

// ES6
import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react'

import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Snackbar,
  TextField,
  InputAdornment,
  IconButton,
  Checkbox,
} from '@material-ui/core/'
import Table from 'react-bootstrap/Table'

import SearchIcon from '@material-ui/icons/Search'
import { useHistory } from 'react-router-dom'
import { AppContext } from '../../../context'
import { RowSelectContext } from '../../../context/RowSelectContext'

import PdfControl from '../PDF/PdfControl'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import LocalStorageService from '../../../LocalStorageService'
import config from '../../../environment'
import Loader from '../../includes/Loader'
import Pagination from '@material-ui/lab/Pagination'
import MuiAlert from '@material-ui/lab/Alert'
import {
  filteerApplicationByMarker,
  getAllNewApplications,
  getSearchedWordMarkData,
  updateApplication,
} from './NewApplicationService'
import { Dropdown } from 'react-bootstrap'
import { updateComparisonFlag } from '../WordMark/WordMarkService'

const localStorageService = LocalStorageService.getService()

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}
let rowSelectedList = []
const NewApplications = () => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const context = useContext(AppContext)
  const contextRowSelect = useContext(RowSelectContext)
  const { setComparisionPdfData } = context
  let history = useHistory()
  const [newApplicationData, setNewApplicationData] = useState([])
  const [publishedMarks, setPublishedMarks] = useState([])
  const [journalData, setJournalData] = useState([])
  const [journal, setJournal] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [currentRowData, setCurrentRowData] = useState([])
  const [infrigementType, setInfrigementType] = useState('sameClass')
  const [rowSelectedData, setRowSelectedData] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [page, setPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [open, setOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedValue, setSelectedValue] = useState('applicationNo')
  const [selectedType, setSelectedType] = useState('NEW')
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const handleValueChange = (event) => {
    setSelectedValue(event.target.value)
  }

  const handleChange = (event) => {
    const { setRowSelectedDataContext } = contextRowSelect
    setIsLoading(true)
    const selectedValue = event.target.value
    setJournal(selectedValue)
    setPage(1)
    rowSelectedList = []
    setNewApplicationData([])
    // getAllWordMarkList(selectedValue, infrigementType, page)
    setRowSelectedDataContext(rowSelectedList)
  }
  const handlePageChange = (event, value) => {
    setIsLoading(true)
    setPage(value)

    //  getAllWordMarkList(journal, infrigementType, value)
  }

  useEffect(() => {
    const { setAuthVisible } = context
    const { setRowSelectedDataContext } = contextRowSelect
    if (localStorageService.getToken()) {
      setAuthVisible(false)
    } else {
      history.push('/login')
    }
    getAllNewApplicationsList()
    return () => {
      setNewApplicationData([])
      setRowSelectedDataContext([])
      rowSelectedList = []
      setComparisionPdfData('newApplicationPdfData', [])
    }
  }, [])

  const getAllNewApplicationsList = async (journalObj) => {
    const res = await getAllNewApplications()

    if (res) {
      setNewApplicationData(res.Items)
      setPageCount(res.Count)
      setComparisionPdfData('newApplicationPdfData', res.Items)
      setIsLoading(false)
    }
  }

  const handleSearch = (e) => {
    const searchValue = e.target.value.trim()

    setSearchQuery(searchValue)
    if (searchValue && searchValue.length > 0) {
      // getSearchedData(searchValue);
    } else {
      setIsLoading(true)
      setCurrentRowData([])
      // getAllWordMarkList(journal, infrigementType, page)
    }
  }

  const getSearchedData = async (valueObj) => {
    let isValid = false
    const isNum = /^\d+$/.test(valueObj)
    if (!isNum && selectedValue === 'applicationNo') {
      setOpen(true)
      setErrorMessage('Please enter number')
      isValid = false
      return
    } else if (isNum) {
      isValid = true
    } else if (!isNum) {
      isValid = true
    } else if (isNum && selectedValue === 'mark') {
      setOpen(true)
      setErrorMessage('Please enter mark')
    }

    if (isValid) {
      setIsLoading(true)
      const resData = await getSearchedWordMarkData(
        journal,
        selectedValue,
        valueObj
      )
      const publishedMark = resData.Items

      if (publishedMark && publishedMark.length > 0) {
        // const sorted = await wordMarkDataTemp.sort((a, b) =>
        //   a.score > b.score ? -1 : 1
        // );

        setNewApplicationData(publishedMark)
        setPageCount(resData.Count)
        setIsLoading(false)
      } else {
        setNewApplicationData([])
        setIsLoading(false)
      }
    }
  }

  const searchHandler = () => {
    getSearchedData(searchQuery)
  }

  const rowSelectHandler = (appNo, e) => {
    const { setRowSelectedDataContext } = contextRowSelect

    if (e.target.checked) {
      const selectedRow = newApplicationData.find(
        (p) => p.applicationNo === appNo.applicationNo
      )
      rowSelectedList.push({ ...selectedRow })

      setRowSelectedData(rowSelectedList)
      setRowSelectedDataContext(rowSelectedList)
    } else {
      const rowIndex = rowSelectedList.findIndex(
        (p) => p.applicationNo === appNo.applicationNo
      )
      rowSelectedList.splice(rowIndex, 1)
      setRowSelectedDataContext(rowSelectedList)
    }
  }

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }
  const updateComparisonFlagHandler = async (id, type) => {
    const res = await updateApplication(id, type)
    // getAllWordMarkList(journal, infrigementType, page)

    let tempData = [...newApplicationData]

    const filteredData = tempData.filter((item) => item.applicationNo !== id)

    setNewApplicationData(filteredData)
    setComparisionPdfData('newApplicationPdfData', filteredData)
    // getAllNewApplicationsList()
    //  setSelectedType('NEW')
  }

  const handleFilterChange = async (e) => {
    const { value } = e.target

    setSelectedType(value)

    let type
    if (value === 'TRACKING') {
      type = 'track'
    } else if (value === 'PUBLISHED') {
      type = 'publish'
    } else {
      type = 'new'
    }
    const res = await filteerApplicationByMarker(type)

    if (res) {
      setComparisionPdfData('newApplicationPdfData', res.Items)
      setNewApplicationData(res.Items)
      setPageCount(res.Count)
    }
  }

  const loadingComponent = (
    <div
      style={{
        position: 'fixed',
        zIndex: 110,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255,255,255,0.8)',
      }}>
      <Loader />
    </div>
  )

  return (
    <>
      <div className='top_head_table new_application_head'>
        <div className='search_wrap'>
          <TextField
            id='filled-basic'
            label='Search'
            variant='filled'
            className='search_box'
            onChange={handleSearch}
            value={searchQuery}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={searchHandler}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControl variant='filled' className='ml-1 select_column'>
            <InputLabel id='demo-simple-select-label'>Select column</InputLabel>
            <Select
              labelId='demo-simple-select-filled-label'
              id='demo-simple-select-filled'
              value={selectedValue}
              onChange={handleValueChange}>
              <MenuItem value='applicationNo'>Application No</MenuItem>
              <MenuItem value='mark'>Mark</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant='filled' className='ml-1 select_column'>
            <InputLabel id='select-marker'>Select Marker</InputLabel>
            <Select
              labelId='select-marker'
              value={selectedType}
              onChange={handleFilterChange}>
              <MenuItem value='NEW'>New</MenuItem>
              <MenuItem value='TRACKING'>Tracking</MenuItem>
              <MenuItem value='PUBLISHED'>Publish</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className='pagination_wrap'>
          <Pagination
            count={pageCount}
            page={page}
            color='primary'
            onChange={handlePageChange}
          />
        </div>
      </div>
      {isLoading && loadingComponent}
      <div className='table_body_wrap'>
        <div className='title_head'>
          <h5>New Applications</h5>
          <PdfControl
            exportToCSV={exportToCSV}
            searchedData={currentRowData}
            rowSelectedData={rowSelectedData}
            fromNewApplication
          />
        </div>
        <div className='comparision_wrap'>
          <Table bordered size='sm'>
            <thead>
              <tr>
                <th></th>
                <th>Application No</th>
                <th>Mark</th>
                <th>Class</th>

                <th>Goods</th>
                <th>Applicant Name</th>
                <th>Application Date</th>
              </tr>
            </thead>
            <tbody>
              {newApplicationData?.length > 0 ? (
                <>
                  {newApplicationData.map((word, i) => (
                    <tr key={i}>
                      <td style={{ width: '80px' }}>
                        <Checkbox
                          color='primary'
                          checked={rowSelectedList.some((p) =>
                            p.applicationNo === word.applicationNo ? 1 : 0
                          )}
                          onClick={(e) => rowSelectHandler(word, e)}
                          inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                        />
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='success'
                            id='dropdown-basic'>
                            <i
                              title={`${
                                word.marker === 'TRACKING'
                                  ? 'Track'
                                  : word.marker === 'PUBLISHED'
                                  ? 'Publish'
                                  : word.marker === 'NONE' && 'none'
                              }`}
                              className={`fas fa-flag ${
                                word.marker === 'TRACKING'
                                  ? 'red'
                                  : word.marker === 'PUBLISHED'
                                  ? 'green'
                                  : word.marker === 'NONE'
                                  ? 'none'
                                  : ''
                              }`}></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              href='javascript:void(0)'
                              title='Track'
                              onClick={() =>
                                updateComparisonFlagHandler(
                                  word.applicationNo,
                                  'TRACKING'
                                )
                              }>
                              <p>Track</p>
                              <i class='fas fa-flag red'></i>
                            </Dropdown.Item>

                            <Dropdown.Item
                              href='javascript:void(0)'
                              onClick={() =>
                                updateComparisonFlagHandler(
                                  word.applicationNo,
                                  'NONE'
                                )
                              }>
                              <p>None</p>

                              <i class='fas fa-flag '></i>
                            </Dropdown.Item>
                            <Dropdown.Item
                              href='javascript:void(0)'
                              title='Publish'
                              className='publish_item'>
                              <p>Publish</p>
                              <i class='fas fa-flag green'></i>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td style={{ width: '135px' }}>{word.applicationNo}</td>
                      <td style={{ width: '435px' }}>
                        {' '}
                        <p>
                          <strong>{word.mark}</strong>{' '}
                        </p>
                        {word.url && (
                          <img className='logo_mark' alt='' src={word.url} />
                        )}
                      </td>
                      <td style={{ width: '60px' }}>{word.class}</td>

                      <td style={{ textAlign: 'left', width: '435px' }}>{word.goods}</td>
                      <td style={{ width: '435px' }}>{word.applicantName}</td>
                      <td style={{ width: '235px' }}>{word.applicationDate}</td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan='8'>Sorry no record found!</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='error'>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

export default NewApplications
