import axios from 'axios'
import LocalStorageService from '../../../LocalStorageService'

const localStorageService = LocalStorageService.getService()

export const getAllJournals = async (id) => {
  try {
    const { data } = await axios.get(`journals?companyId=${id}`)
    return data
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
export const getPublishedMark = async (journal) => {
  try {
    const { data } = await axios.get(
      `journals/${journal}/published?companyId=${localStorageService.getCompanyId()}`
    )
    return Promise.resolve(data)
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}
export const getSearchedWordMarkData = async (journal, key, value) => {
  try {
    const { data } = await axios.get(
      `journals/${journal}/published?companyId=${localStorageService.getCompanyId()}&searchKey=${key}&searchValue=${value}`
    )
    return Promise.resolve(data)
  } catch (err) {
    console.log(err, 'error')

    return err
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getPublishedMark,
  getAllJournals,
  getSearchedWordMarkData,
}
