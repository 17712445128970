/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'

import { Snackbar } from '@material-ui/core/'
import Logo from '../../assets/img/ipbloc.png'
import LocalStorageService from '../../LocalStorageService'
import ReactFormInputValidation from 'react-form-input-validation'
import MuiAlert from '@material-ui/lab/Alert'
import ForgotPassword from '../Auth/ForgotPasswordModal'

import { Modal } from 'react-bootstrap'
import { userLogin } from '../Auth/AuthService'

import { AppContext } from '../../context'
import { Link, withRouter } from 'react-router-dom'

/**
 * @author
 * @class Header
 **/

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const localStorageService = LocalStorageService.getService()

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: {
        username: '',
        password: '',
      },
      errors: {},
      open: false,
      showForgotModal: false,
      showContactModal: false,
      remember: false,
      showLogin: false,
    }
    const { history } = this.props
    this.form = new ReactFormInputValidation(this)
    this.form.useRules({
      username: 'required',
      password: 'required',
    })
    this.form.onformsubmit = async (fields) => {
      const { remember } = this.state

      const loginObj = {
        userId: fields.username,
        password: fields.password,
      }
      const res = await userLogin(loginObj)

      if (res.token) {
        localStorageService.setToken(res.token)
        localStorageService.setCompanyId(res.companyId)
        localStorageService.setUserRole(res.role)
        localStorageService.setUserName(res.name)
        localStorageService.setUserId(fields.username)
        if (remember) {
          fields.isChecked = remember
          localStorageService.setRememberData(JSON.stringify(fields))
        }
        this.handleCloseLogin()
        history.push(`/comparisons`)
      } else {
        this.setState({
          open: true,
        })
      }
    }
  }

  handleCloseLogin = () => this.setState({ showLogin: false })
  handleShowLogin = () => this.setState({ showLogin: true })

  componentDidMount() {
    const { history } = this.props
    const { setAuthVisible } = this.context

    const rememberData = JSON.parse(localStorageService.getRememberData())

    if (rememberData) {
      this.setState({
        fields: rememberData,
        remember: rememberData.isChecked,
      })
    }

    setAuthVisible(true)
    if (localStorageService.getToken()) {
      history.push(`/comparisons`)
    }
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({
      open: false,
    })
  }

  handleShowForgot = () => {
    this.setState({
      showForgotModal: true,
    })
  }
  handleCloseForgot = () => {
    this.setState({
      showForgotModal: false,
    })
  }
  handleShowContact = () => {
    this.setState({
      showContactModal: true,
    })
  }
  handleCloseContact = () => {
    this.setState({
      showContactModal: false,
    })
  }

  handleRemeberCheck = (e) => {
    this.setState({
      remember: e.target.checked,
    })

    if (!e.target.checked) {
      localStorage.removeItem('remember')
    }
  }

  render() {
    const { fields, errors, showLogin, open, showForgotModal } = this.state

    return (
      <>
        <div id='menu_area' className='menu-area fixed-top'>
          <div className='container'>
            <div className='row'>
              <nav className='navbar navbar-light navbar-expand-lg mainmenu'>
                <button
                  className='navbar-toggler'
                  type='button'
                  data-toggle='collapse'
                  data-target='#navbarSupportedContent'
                  aria-controls='navbarSupportedContent'
                  aria-expanded='false'
                  aria-label='Toggle navigation'>
                  <span className='navbar-toggler-icon'></span>
                </button>
                <Link className='navbar-brand' to='/'>
                  <img style={{ height: 66 }} src={Logo} alt='TM Logo' />
                  <span>
                    <strong>TM</strong>SPY
                  </span>
                </Link>
                <div
                  className='collapse navbar-collapse'
                  id='navbarSupportedContent'>
                  <ul className='navbar-nav ml-auto'>
                    {/* <li className="nav-item">
                  {' '}
                  <a className="nav-link" href="#features">
                    Features
                  </a>{' '}
                </li>
                <li className="nav-item">
                  {' '}
                  <a className="nav-link" href="#pricing">
                    Pricing{' '}
                  </a>{' '}
                </li>
                <li className="nav-item">
                  {' '}
                  <a className="nav-link" href="#webinars">
                    Webinars
                  </a>{' '}
                </li>
                <li className="nav-item">
                  {' '}
                  <a className="nav-link" href="blog.html">
                    Blog
                  </a>{' '}
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Pages
                  </a>
                  <ul
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <a href="index-2.html">Home Page 1</a>
                    </li>
                    <li>
                      <a href="home2.html">Home Page 2</a>
                    </li>
                    <li>
                      <a href="home3.html">Home Page 3</a>
                    </li>
                    <li>
                      <a href="home4.html">Home Page 4</a>
                    </li>
                    <li>
                      <a href="blog.html">Blog</a>
                    </li>
                    <li>
                      <a href="blog-single.html">Blog Single</a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  {' '}
                  <a
                    className="btn btn-secondary text-white btn-round btn-nav"
                    data-toggle="modal"
                    data-target="#signup-modal"
                  >
                    Signup
                  </a>{' '}
                </li> */}
                    <li className='nav-item'>
                      {' '}
                      <a
                        className='btn btn-primary btn-shadow  text-white btn-round btn-nav'
                        onClick={this.handleShowLogin}
                        href='javascript:void(0)'>
                        Login
                      </a>{' '}
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <Modal
          show={showLogin}
          onHide={this.handleCloseLogin}
          centered
          className='cs-modal'>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className='p-0'>
            <div class='   '>
              <div class='row'>
                <div class='col-lg-12'>
                  <div class='sign-form m-0 p-5'>
                    <div
                      class='show-sm modal-fixed-close'
                      data-dismiss='modal'
                      aria-label='Close'>
                      <i class='fa fa-times-circle'></i>
                    </div>
                    <h2 class='sign-form-head text-center'>LOGIN</h2>
                    <form
                      noValidate
                      autoComplete='off'
                      onSubmit={this.form.handleSubmit}>
                      <div class='form-group  uiIcon'>
                        <input
                          type='text'
                          class='input form-control'
                          placeholder='Email Address'
                          id='email'
                          required
                          onBlur={this.form.handleBlurEvent}
                          name='username'
                          onChange={this.form.handleChangeEvent}
                          value={fields.username}
                        />
                        <svg
                          class='svgIcon fill-smoke--d'
                          width='30'
                          height='30'
                          viewBox='0 -6 30 30'
                          enable-background='new 0 -6 30 30'>
                          <path d='M15 11.9c-.9 0-1.7-.2-2.3-.7l-5.7-4.4v8.1c0 .1 0 .3.1.4.1.1.2.2.3.2 2.5.4 5 .5 7.5.5s5-.2 7.5-.5c.1 0 .3-.1.4-.2.2-.1.2-.3.2-.4v-8.1l-5.7 4.4c-.6.5-1.4.7-2.3.7zm-1.3-2.3c.3.3.8.4 1.3.4s1-.1 1.3-.4l6.1-4.7c.3-.3.5-.6.6-1v-.8c0-.1 0-.3-.1-.4-.1-.1-.2-.2-.4-.2-2.5-.3-5-.5-7.5-.5s-5 .2-7.5.5c-.1 0-.2.1-.3.2-.2.1-.2.3-.2.4v.9c.1.3.3.6.6 1l6.1 4.6z'></path>
                        </svg>
                        <label className='error'>
                          {errors.username ? 'This field is required' : ''}
                        </label>
                      </div>

                      <div class='form-group  uiIcon'>
                        <input
                          type='password'
                          class='input form-control'
                          placeholder='Password'
                          required
                          id='password'
                          name='password'
                          onBlur={this.form.handleBlurEvent}
                          onChange={this.form.handleChangeEvent}
                          value={fields.password}
                        />
                        <svg
                          class='svgIcon fill-smoke--d'
                          width='30'
                          height='30'
                          viewBox='0 -6 30 30'
                          enable-background='new 0 -6 30 30'>
                          <path d='M21.6 8.3c-.2-.2-.5-.3-.9-.3h-.7v-2.6c0-1.3-.5-2.4-1.4-3.2-.9-.8-2.1-1.2-3.6-1.2s-2.7.4-3.6 1.2c-.9.8-1.4 1.9-1.4 3.2v2.6h-.7c-.3 0-.7.1-.9.4-.3.2-.4.5-.4.8v6.1c0 .3.1.5.3.7.2.2.4.3.7.4 1.9.4 3.9.6 6 .6 2 0 4-.2 5.9-.6.3-.1.5-.2.7-.4.2-.2.3-.4.3-.7v-6.1c.1-.3 0-.6-.3-.9zm-8.6-2.4c0-1.3.7-1.9 2-1.9s2 .6 2 1.9v2.1h-4v-2.1z'></path>
                        </svg>
                        <label className='error'>
                          {errors.password ? errors.password : ''}
                        </label>
                      </div>

                      <div class='form-group'>
                        <button type='submit' class='btn btn-primary btn-block'>
                          Login
                        </button>
                      </div>
                    </form>
                    <div class='sign-form-text'>
                      <p>
                        <a
                          href='javascript:void(0)'
                          class='float-right text-primary'
                          onClick={this.handleShowForgot}>
                          Forgot Password{' '}
                          <i class='fa fa-question-circle ml-1'></i>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity='error'>
            Invalid email or password
          </Alert>
        </Snackbar>
        <ForgotPassword
          show={showForgotModal}
          handleClose={this.handleCloseForgot}
        />
      </>
    )
  }
}

Header.propTypes = {}
Header.contextType = AppContext
export default withRouter(Header)
