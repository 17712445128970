import React, { useEffect, useContext } from 'react'
import { AppContext } from '../../context'
import Footer from '../includes/Footer'
import Header from '../includes/Header'

/**
 * @author
 * @function Terms
 **/

const Terms = (props) => {
  const context = useContext(AppContext)
  const { setAuthVisible } = context

  useEffect(() => {
    setAuthVisible(true)
  }, [setAuthVisible])
  return (
    <>
      <Header />
      <div className='container'>
        <div
          className='box'
          style={{
            marginTop: '120px',
            marginBottom: '40px',
            background: 'white',
          }}>
          <div className='row'>
            <div className='tg-aboutus'>
              <div className='col-xs-12 col-sm-12'>
                <div className='tg-textshortcode'>
                  <div className='tg-titleshortcode'>
                    <h2>Terms and Conditions</h2>
                  </div>

                  <p>LAST REVISION: [01-05-2021]</p>
                  <div className='tg-description'>
                    <p>
                      PLEASE READ CAREFULLY. BY ACCESING OR USING THIS WEBSITE
                      YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS
                      OF THIS AGREEMENT TOGETHER WITH ANY TERMS, POLICIES, OR
                      DISCLOSURES THAT MAY BE INCORPORATED BY REFERENCE.{' '}
                    </p>
                    <p>
                      This Agreement governs your use of this Website operated
                      by Business‘s offer of Services on this Website. This
                      Agreement includes, and incorporates by this reference,
                      the policies and guidelines referenced below. Business
                      reserves the right to change or revise the terms and
                      conditions of this Agreement at any time by posting any
                      changes or a revised Agreement on this Website. Business
                      will alert you that changes or revisions have been made by
                      indicating on the top of this Agreement the date it was
                      last revised. The changed or revised Agreement will be
                      effective immediately after it is posted on this Website.
                      Your use of the Website following the posting of any such
                      changes or of a revised Agreement will constitute your
                      acceptance of any such changes or revisions. Business
                      encourages you to review this Agreement whenever you visit
                      the Website to make sure that you understand the terms and
                      conditions governing the use of the Website. This
                      Agreement does not alter in any way the terms or
                      conditions of any other written agreement you may have
                      with Business for other Services. If you do not agree to
                      this Agreement (including any referenced policies or
                      guidelines), please immediately terminate your use of the
                      Website.
                    </p>
                    <h3>I. DEFINITIONS</h3>
                    <p>
                      <b>“Agreement”</b> means Terms of Service Agreement.
                    </p>
                    <p>
                      <b>“Website”</b> means https://tmspy.ipbloc.in
                    </p>
                    <p>
                      <b>“Business”</b> means GENIE IP SERVICES PVT. LTD.
                    </p>
                    <p>
                      <b>“Services”</b> means legal services related to
                      intellectual property rights including and not limited to
                      promotion, IP protection, negotiations, assignments,
                      objections, evaluation, filings, opposition and renewals.
                    </p>
                    <p>
                      <b>“Third-Party content”</b> means any content made
                      available on Website that are not Services provided by
                      other Users.
                    </p>
                    <p>
                      <b>“User”</b> means any individual, company, organization
                      accessing the website whether registered or not.
                    </p>
                    <h3>II. WEBSITE</h3>
                    <p>
                      {' '}
                      Proprietary Rights. Business has proprietary rights and
                      trade secrets. Business also has rights to all trademarks
                      and specific layouts of this webpage, including calls to
                      action, text placement, images and other information.
                    </p>
                    <p>
                      Sales Tax/GST. If you purchase any Services, you will be
                      responsible for paying any applicable taxes not limited to
                      sales tax or GST.
                      <p>
                        Terms of Offer. This Website offers certain Services. By
                        placing an order for Services through this Website, you
                        agree to the terms set forth in this Agreement.
                      </p>
                    </p>
                    <p>
                      Customer Solicitation: Unless you notify our third-party
                      call center reps or direct Business sales reps, while they
                      are calling you, of your desire to opt out from further
                      direct company communications and solicitations, you are
                      agreeing to continue to receive further emails and call
                      solicitations Business and its designated in house or
                      third-party call team(s).
                    </p>
                    <p>
                      Opt Out Procedure: We provide 2 easy ways to opt out of
                      from future solicitations.
                    </p>
                    <ul>
                      <li>
                        You may choose to opt out, via sending your email
                        address to – tmspy@ipbloc.in.
                      </li>
                      <li>
                        You may also choose to opt out, via sending a written
                        request to – GENIE IP SERVICES PVT. LTD., 11/12 Upper
                        Ground Floor, West Patel Nagar, New Delhi -110008.
                      </li>
                    </ul>
                    <p>
                      Website material. Website materials are not legal advice,
                      and are not guaranteed to be correct, complete, or
                      up-to-date. Business does not guarantee that any of the
                      Website material is current. Without limitation, any
                      Website material is intended for general informational
                      purposes only, is not intended to and does not create an
                      attorney-client relationship between you and Business, and
                      you shall not rely upon any such Website materials. You
                      understand that any information or other postings to the
                      Website, any other communication via Business, or any
                      other type of Website materials, is not confidential and
                      is not subject to attorney-client privilege.
                    </p>
                    <p>
                      Intellectual Property; Third Party Links. In addition to
                      making Services available, this Website also offers
                      information and marketing materials. This Website also
                      offers information, both directly and through indirect
                      links to third-party websites, about services related to
                      Intellectual Property. Business does not always create the
                      information offered on this Website; instead the
                      information is often gathered from other sources. To the
                      extent that Business does create the content on this
                      Website, such content is protected by intellectual
                      property laws of the India, foreign nations, and
                      international bodies. Unauthorized use of the material may
                      violate copyright, trademark, and/or other laws. You
                      acknowledge that your use of the content on this Website
                      is for personal, noncommercial use. Any links to
                      third-party websites are provided solely as a convenience
                      to you. Business does not endorse the contents on any such
                      third-party websites. Business is not responsible for the
                      content of or any damage that may result from your access
                      to or reliance on these third-party websites. If you link
                      to third-party websites, you do so at your own risk.
                    </p>
                    <p>
                      Use of Website. Business may provide data, information, or
                      content related to but not restricted to intellectual
                      property portfolio assembly and management approaches.
                    </p>
                    <p>
                      You will not use the Website for illegal purposes. You
                      will (1) abide by all applicable local, state, national,
                      and international laws and regulations in your use of the
                      Website (including laws regarding intellectual property),
                      (2) not interfere with or disrupt the use and enjoyment of
                      the Website by other users, (3) not resell material on the
                      Website, (4) not engage, directly or indirectly, in
                      transmission of "spam", chain letters, junk mail or any
                      other type of unsolicited communication, and (5) not
                      defame, harass, abuse, or disrupt other users of the
                      Website.
                    </p>
                    <p>
                      You alone will bear the sole responsibility of evaluating
                      the merits and risks associated with the use of any data,
                      information, content, Services or opportunities on the
                      Website before making any decision based on such data,
                      information, content, Services or opportunities. IN
                      EXCHANGE FOR USING SUCH DATA, INFORMATION, CONTENT,
                      Services OR OPPORTUNITIES, YOU AGREE NOT TO HOLD Business
                      or Website OR ANY OF ITS THIRD-PARTY CONTENT PROVIDERS
                      LIABLE FOR ANY POSSIBLE CLAIM FOR DAMAGES ARISING FROM ANY
                      DECISION YOU MAKE BASED ON DATA, INFORMATION, CONTENT,
                      Services OR OPPORTUNITIES MADE AVAILABLE TO YOU THROUGH
                      THE Website.
                    </p>
                    <p>
                      Errors or Omissions. Business is not responsible for any
                      error or omission in any Website materials, including
                      without limitation professional profile information,
                      professional information, or information about any entity.
                      While Business attempts to make your access, and use of
                      the website safe, business cannot and does not represent
                      or warrant that the website or its servers, or any content
                      or website materials are free of viruses or other harmful
                      components.
                    </p>
                    <p>
                      License. By using this Website, you are granted a limited,
                      non-exclusive, non-transferable right to use the content
                      and Website materials on the Website in connection with
                      your normal, noncommercial, use of the Website. You may
                      not copy, reproduce, transmit, distribute, or create
                      derivative works of such content or information without
                      express written authorization from Business or the
                      applicable third party (if third party content is at
                      issue).
                    </p>
                    <h3>III. USER’s RESPONSIBILITIES AND REPRESENTATIONS</h3>
                    <p>
                      Not an agent of Business. By listing in the Website,
                      providing any Third-Party content, Services,
                      opportunities, data, providing or purchasing Services,
                      using our free or paid features, or engaging, without
                      limitation, in any manner on the Website, you agree you
                      are not becoming part of the Website and shall not hold
                      yourself out to be a member of the Website or the
                      Business.
                    </p>
                    <p>
                      User Account. In consideration of your use of the Website,
                      you agree to (a) provide accurate, current and complete
                      information about you (including, but not limited, to your
                      email address) as may be prompted by any registration
                      forms on the Website (“Registration Data”); (b) maintain
                      the security of your password and identification; (c)
                      maintain and promptly update the Registration Data or any
                      other information you provide to the Business, and keep it
                      accurate, current and complete; and (d) accept all risks
                      of unauthorized access to the Registration Data and any
                      other information you provide to Business. You agree to
                      allow Business to rely on any information submitted by you
                      in relation to your activities and your account.
                    </p>
                    <p>
                      You are required to register for your account with a valid
                      email address, and to take complete responsibility for the
                      validity and performance of this email address at all
                      times. You are required to update your account promptly
                      regarding any and all changes to this email address. Any
                      change to your account regarding this email address will
                      be deemed to be under your explicit approval, even if the
                      change is made by a third-party accessing your account
                      with or without your approval. All communications between
                      Business or users and you will be directed to this email
                      address. You are responsible for maintaining the
                      confidentiality of your account password and for
                      restricting access to your account. You agree to accept
                      responsibility for any activities that occur under your
                      account. You may not use a name in connection with
                      operating your account that confuses, misleads, or
                      otherwise deceives anyone with respect to your identity.
                    </p>
                    <p>
                      You agree not to access or use, or attempt to access or
                      use, the Website or any part thereof using the identity or
                      the Registration Data of any person other than yourself.
                    </p>
                    <p>
                      Age. We do not permit anyone under the age of 18 to use
                      our Services. We reserve the right to terminate, with
                      immediate effect and without notice, the account of any
                      User that we learn is under the age of 18.
                    </p>
                    <h3>IV. TIMELINES</h3>
                    <p>
                      Timelines mentioned for work completion are estimations
                      and should not be construed as an agreed promise from
                      Business. Work completion is dependent on multiple
                      factors, such as government departments and third-party
                      vendors (e.g., CAs, CS, payment gateway, cloud service
                      provider). Business will have limited warranties in such
                      cases. Business won’t be at risk for any immediate,
                      backhanded, coincidental, considerable or model misfortune
                      or harms which may be brought about by you as a
                      consequence of utilizing our Services and Website
                      material, or as an aftereffect of any progressions,
                      information misfortune or defilement, cancellation, loss
                      of access, or downtime to the full degree that appropriate
                      restriction of obligation laws apply.
                    </p>
                    <h3>V. DISCLAIMER OF WARRANTIES</h3>
                    <p>
                      YOUR USE OF THIS Website AND/OR Services ARE AT YOUR SOLE
                      RISK. THE Website AND Services ARE OFFERED ON AN "AS IS"
                      AND "AS AVAILABLE" BASIS. Business EXPRESSLY DISCLAIMS ALL
                      WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                      INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                      NON-INFRINGEMENT WITH RESPECT TO THE Services OR Website
                      content, OR ANY RELIANCE UPON OR USE OF THE Website
                      CONTENT OR Services. ("Services" INCLUDE TRIAL Version.)
                    </p>
                    <p>
                      WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, Business
                      MAKES NO WARRANTY:
                    </p>
                    <p>
                      THAT THE INFORMATION PROVIDED ON THIS WEBSITE IS ACCURATE,
                      RELIABLE, COMPLETE, OR TIMELY.
                    </p>
                    <p>
                      THAT THE LINKS TO THIRD-PARTY WEBSITES ARE TO INFORMATION
                      THAT IS ACCURATE, RELIABLE, COMPLETE, OR TIMELY.
                    </p>
                    <p>
                      {' '}
                      NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
                      OBTAINED BY YOU FROM THIS WEBSITE WILL CREATE ANY WARRANTY
                      NOT EXPRESSLY STATED HEREIN.
                    </p>
                    <p>
                      AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
                      Services OR THAT DEFECTS IN Services WILL BE CORRECTED.
                    </p>
                    <p>
                      REGARDING ANY Services PURCHASED OR OBTAINED THROUGH THE
                      WEBSITE.
                    </p>
                    <p>
                      SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
                      WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY
                      TO YOU.
                    </p>
                    <h3> VI. LIMITATION OF LIABILITY</h3>
                    <p>
                      Business’s ENTIRE LIABILITY, AND YOUR EXCLUSIVE REMEDY, IN
                      LAW, IN EQUITY, OR OTHWERWISE, WITH RESPECT TO THE Website
                      CONTENT AND Services AND/OR FOR ANY BREACH OF THIS
                      AGREEMENT IS SOLELY LIMITED TO THE AMOUNT YOU PAID, LESS
                      SHIPPING, HANDLING AND TAXES, FOR Services PURCHASED VIA
                      THE WEBSITE.
                    </p>
                    <p>
                      {' '}
                      Business WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
                      INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES IN CONNECTION
                      WITH THIS AGREEMENT OR THE Services IN ANY MANNER,
                      INCLUDING LIABILITIES RESULTING FROM (1) THE USE OR THE
                      INABILITY TO USE THE Website CONTENT OR Services; (2) THE
                      COST OF PROCURING SUBSTITUTE Services OR CONTENT; (3) ANY
                      Services PURCHASED OR OBTAINED OR TRANSACTIONS ENTERED
                      INTO THROUGH THE WEBSITE; OR (4) ANY LOST PROFITS YOU
                      ALLEGE.
                    </p>
                    <p>
                      SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR
                      EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
                      DAMAGES SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO
                      YOU.
                    </p>
                    <h3>VII. INDEMNIFICATION</h3>
                    <p>
                      You will release, indemnify, defend and hold harmless
                      Business, and any of its contractors, agents, employees,
                      officers, directors, shareholders, affiliates and assigns
                      from all liabilities, claims, damages, costs and expenses,
                      including reasonable attorneys' fees and expenses, of
                      third parties relating to or arising out of (1) this
                      Agreement or the breach of your warranties,
                      representations and obligations under this Agreement; (2)
                      the Website content or your use of the Website content;
                      (3) the Services or your use of the Services (including
                      Trial Version); (4) any intellectual property or other
                      proprietary right of any person or entity; (5) your
                      violation of any provision of this Agreement; or (6) any
                      information or data you supplied to Business. When
                      Business is threatened with suit or sued by a third party,
                      Business may seek written assurances from you concerning
                      your promise to indemnify Business; your failure to
                      provide such assurances may be considered by Business to
                      be a material breach of this Agreement. Business will have
                      the right to participate in any defense by you of a
                      third-party claim related to your use of any of the
                      Website content or Services, with counsel of Business
                      choice at its expense. Business will reasonably cooperate
                      in any defense by you of a third-party claim at your
                      request and expense. You will have sole responsibility to
                      defend Business against any claim, but you must receive
                      Business’s prior written consent regarding any related
                      settlement. The terms of this provision will survive any
                      termination or cancellation of this Agreement or your use
                      of the Website or Services.
                    </p>
                    <h3>VIII. PRIVACY</h3>
                    <p>
                      Business believes strongly in protecting user privacy.
                      Please refer to Business’s privacy policy (separate
                      document), that is posted on the Website.
                    </p>
                    <h3>X. CANCELLATION AND REFUNDS</h3>
                    <p>
                      Cancellation of the transaction related to Services is
                      unrealistic once the payment has been made. No refunds
                      will be given with the exception of in the occasion of
                      cancellation or non-execution of administration by the
                      Business
                    </p>
                    <h3>IX. GENERAL</h3>
                    <p>
                      {' '}
                      Advertisement and Promotions. Business may run
                      advertisements and promotions from third parties on the
                      Website. Your business dealings or correspondence with, or
                      participation in promotions of, advertisers other than
                      Business, and any terms, conditions, warranties, or
                      representations associated with such dealings, are solely
                      between you and such third-parties. Business is not
                      responsible or liable for any loss or damage incurred as
                      the result of any such dealings, or as the result of the
                      presence of any advertisers on the Website other than
                      Business.
                    </p>
                    <p>
                      Force Majeure. Business will not be deemed in default
                      hereunder or held responsible for any cessation,
                      interruption or delay in the performance of its
                      obligations hereunder due to earthquake, flood, fire,
                      storm, natural disaster, act of God, war, terrorism, armed
                      conflict, labor strike, lockout, or boycott.
                    </p>
                    <p>
                      Cessation of Operation. Business may at any time, in its
                      sole discretion and without advance notice to you, cease
                      operation of the Website and distribution of the Services.
                    </p>
                    <p>
                      Entire Agreement. This Agreement comprises the entire
                      agreement between you and Business and supersedes any
                      prior agreements pertaining to the subject matter
                      contained herein.
                    </p>
                    <p>
                      {' '}
                      Effect of Waiver. The failure of Business to exercise or
                      enforce any right or provision of this Agreement will not
                      constitute a waiver of such right or provision. If any
                      provision of this Agreement is found by a court of
                      competent jurisdiction to be invalid, the parties
                      nevertheless agree that the court should endeavor to give
                      effect to the parties' intentions as reflected in the
                      provision, and the other provisions of this Agreement
                      remain in full force and effect.
                    </p>
                    <p>
                      Governing Law; Jurisdiction. This Website originates from
                      the State of Delhi, India. This Agreement will be governed
                      by the laws of the State of Delhi, India without regard to
                      its conflict of law principles to the contrary. Neither
                      you nor Business will commence or prosecute any suit,
                      proceeding or claim to enforce the provisions of this
                      Agreement, to recover damages for breach of or default of
                      this Agreement, or otherwise arising under or by reason of
                      this Agreement, other than in courts located in State of
                      Delhi, India. By using this Website or ordering Services,
                      you consent to the jurisdiction and venue of such courts
                      in connection with any action, suit, proceeding or claim
                      arising under or by reason of this Agreement. You hereby
                      waive any right to trial by jury arising out of this
                      Agreement and any related documents.
                    </p>
                    <p>
                      {' '}
                      Statute of Limitation. You agree that regardless of any
                      statute or law to the contrary, any claim or cause of
                      action arising out of or related to use of the Website or
                      Services or this Agreement must be filed within one (1)
                      year after such claim or cause of action arose or be
                      forever barred.
                    </p>
                    <p>
                      {' '}
                      Waiver of Class Action Rights. BY ENTERING INTO THIS
                      AGREEMENT, YOU HEREBY IRREVOCABLY WAIVE ANY RIGHT YOU MAY
                      HAVE TO JOIN CLAIMS WITH THOSE OF OTHER IN THE FORM OF A
                      CLASS ACTION OR SIMILAR PROCEDURAL DEVICE. ANY CLAIMS
                      ARISING OUT OF, RELATING TO, OR CONNECTION WITH THIS
                      AGREEMENT MUST BE ASSERTED INDIVIDUALLY.
                    </p>
                    <p>
                      {' '}
                      Termination. Business reserves the right to terminate your
                      access to the Website if it reasonably believes, in its
                      sole discretion, that you have breached any of the terms
                      and conditions of this Agreement. Following termination,
                      you will not be permitted to use the Website and Business
                      may, in its sole discretion and without advance notice to
                      you, cancel any outstanding orders for Services. If your
                      access to the Website is terminated, Business reserves the
                      right to exercise whatever means it deems necessary to
                      prevent unauthorized access of the Website. This Agreement
                      will survive indefinitely unless and until Business
                      chooses, in its sole discretion and without advance to
                      you, to terminate it.
                    </p>{' '}
                    <p>
                      Domestic Use. Business makes no representation that the
                      Website or Services are appropriate or available for use
                      in locations outside India. Users who access the Website
                      from outside India do so at their own risk and initiative
                      and must bear all responsibility for compliance with any
                      applicable local laws.
                    </p>
                    <p>
                      Assignment. You may not assign your rights and obligations
                      under this Agreement to anyone. Business may assign its
                      rights and obligations under this Agreement in its sole
                      discretion and without advance notice to you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Terms
