import React, { Component } from 'react'
import {
  Button,
  CssBaseline,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  Container,
  withStyles,
  Snackbar,
} from '@material-ui/core/'
import MuiAlert from '@material-ui/lab/Alert'
import { Link, withRouter } from 'react-router-dom'
import ReactFormInputValidation from 'react-form-input-validation'

import { AppContext } from '../../context'
import { userLogin } from './AuthService'
import LocalStorageService from '../../LocalStorageService'
import Logo from '../../assets/img/ipbloc.png'
import ForgotPassword from './ForgotPasswordModal'
import ContactModal from './ContactModal'

const localStorageService = LocalStorageService.getService()

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
})

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: {
        username: '',
        password: '',
      },
      errors: {},
      open: false,
      showForgotModal: false,
      showContactModal: false,
      remember: false,
    }
    this.form = new ReactFormInputValidation(this)
    this.form.useRules({
      username: 'required',
      password: 'required',
    })
    this.form.onformsubmit = async (fields) => {
      const { history } = this.props
      const { remember } = this.state

      const loginObj = {
        userId: fields.username,
        password: fields.password,
      }
      const res = await userLogin(loginObj)

      if (res.token) {
        localStorageService.setToken(res.token)
        localStorageService.setCompanyId(res.companyId)
        localStorageService.setUserRole(res.role)
        localStorageService.setUserName(res.name)
        localStorageService.setUserId(fields.username)
        if (remember) {
          fields.isChecked = remember
          localStorageService.setRememberData(JSON.stringify(fields))
        }

        history.push(`/comparisons`)
      } else {
        this.setState({
          open: true,
        })
      }
    }
  }

  componentDidMount() {
    const { history } = this.props
    history.push(`/`)
    const { setAuthVisible } = this.context

    const rememberData = JSON.parse(localStorageService.getRememberData())

    if (rememberData) {
      this.setState({
        fields: rememberData,
        remember: rememberData.isChecked,
      })
    }

    setAuthVisible(true)
    if (localStorageService.getToken()) {
      history.push(`/comparisons`)
    }
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({
      open: false,
    })
  }

  handleShowForgot = () => {
    this.setState({
      showForgotModal: true,
    })
  }
  handleCloseForgot = () => {
    this.setState({
      showForgotModal: false,
    })
  }
  handleShowContact = () => {
    this.setState({
      showContactModal: true,
    })
  }
  handleCloseContact = () => {
    this.setState({
      showContactModal: false,
    })
  }

  handleRemeberCheck = (e) => {
    this.setState({
      remember: e.target.checked,
    })

    if (!e.target.checked) {
      localStorage.removeItem('remember')
    }
  }

  render() {
    const { classes } = this.props
    const {
      fields,
      errors,
      open,
      showForgotModal,
      showContactModal,
      remember,
    } = this.state
    return (
      <>
        <Container component="main" maxWidth="xs" className="auth_wrapper">
          <CssBaseline />
          <div className={classes.paper}>
            <div className="logo_wrap">
              <img alt="logo" src={Logo} className="logo_img" />
              <span>TMSPY</span>
            </div>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form
              className={classes.form}
              noValidate
              autoComplete="off"
              onSubmit={this.form.handleSubmit}
            >
              <FormControl variant="filled" fullWidth>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="username"
                  autoComplete="email"
                  autoFocus
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent}
                  value={fields.username}
                />
                <label className="error">
                  {errors.username ? 'This field is required' : ''}
                </label>
              </FormControl>
              <FormControl variant="filled" fullWidth>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent}
                  value={fields.password}
                  autoComplete="current-password"
                />
                <label className="error">
                  {errors.password ? errors.password : ''}
                </label>
              </FormControl>

              <FormControlLabel
                control={
                  <Checkbox
                    value={remember}
                    color="primary"
                    checked={remember}
                    onChange={this.handleRemeberCheck}
                  />
                }
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.loginHandler}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    href="#"
                    onClick={this.handleShowForgot}
                    variant="body2"
                  >
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link onClick={this.handleShowContact}>
                    {"Don't have an account? Contact us"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
          {/* <Box mt={8}>
          <Copyright />
        </Box> */}
        </Container>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <Alert onClose={this.handleClose} severity="error">
            Invalid email or password
          </Alert>
        </Snackbar>
        <ForgotPassword
          show={showForgotModal}
          handleClose={this.handleCloseForgot}
        />
        <ContactModal
          show={showContactModal}
          handleClose={this.handleCloseContact}
        />
      </>
    )
  }
}

export default withStyles(useStyles)(withRouter(Login))
Login.contextType = AppContext
